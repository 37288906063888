import React, { useState, useEffect } from 'react'
import { Grid, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Loader from '../../components/Loader/'
import { makeCall } from '../../utils/api'
import { Container } from './style'
import { ChannelDataType } from './types'
import Header from './Header'
import ChannelsTable from './ChannelsTable'
import Filters from './Filters'
import { orderBy } from 'lodash'

const useStyles = makeStyles({
  root: {
    marginTop: '22px',
    position: 'relative',
  },
  paperContainer: {
    padding: '16px',
    position: 'relative',
  },
})

export default function ChannelsPage() {
  const [isLoading, setIsLoading] = useState(true)
  const [masterData, setMasterData] = useState<ChannelDataType[]>([])
  const [channels, setChannels] = useState<ChannelDataType[]>([])
  const [pageCount, setPageCount] = useState(0)
  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [sortDirection, setSortDirection] = useState<'desc' | 'asc'>('asc')
  const PER_PAGE = 20

  const classes = useStyles()

  useEffect(() => {
    const getData = async () => {
      const url = `channels`
      const { data } = await makeCall('GET', url)
      setChannels(data)
      setMasterData(data)
      setPageCount(Math.ceil(data.length / PER_PAGE))
      setIsLoading(false)
    }
    getData()
  }, [])

  useEffect(() => {
    if (search) {
      const results = [...masterData].filter((row) =>
        row.channelName.toLowerCase().includes(search.toLowerCase())
      )
      setChannels(results)
    } else {
      setChannels(masterData)
    }
  }, [search, masterData])

  const handleSort = (newSort: string) => {
    let newSortDirection: 'asc' | 'desc' = 'asc'
    if (sortBy === newSort) {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }
    const sortedData = orderBy(channels, newSort, newSortDirection)
    setChannels(sortedData)
    setSortBy(newSort)
    setSortDirection(newSortDirection)
  }

  return (
    <>
      <Loader loading={isLoading} />
      <Container>
        <Header count={channels.length} />
        <Grid className={classes.root} container>
          <Grid item xs={12}>
            <Paper
              className={classes.paperContainer}
              elevation={0}
              variant="outlined"
            >
              <Filters search={search} setSearch={setSearch} />
              <ChannelsTable
                channels={channels}
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleSort={handleSort}
                pageCount={pageCount}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
