import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import Loader from '../../../components/Loader'
import { makeCall } from '../../../utils/api'
import { Container } from '../../../elements/container'
import { Channel, Data, Record } from './types'
import Create from './Create'
import { orderBy } from 'lodash'
import { DataTable } from './Table'

export default function Webhook() {
  const [isLoading, setIsLoading] = useState(true)
  const [isCreate, setIsCreate] = useState(false)
  const [isRefresh, setIsRefresh] = useState(true)
  const [sortBy, setSortBy] = useState('')
  const [sortDirection, setSortDirection] = useState<'desc' | 'asc'>('asc')
  const [searchVal, setSearchVal] = useState('')
  const [channels, setChannels] = useState<Channel[]>([])
  const [data, setData] = useState<Data[]>([])
  const [record, setRecord] = useState<Record>(null)

  useEffect(() => {
    makeCall('GET', 'channels').then((res) => {
      setChannels([
        { channelUuid: 'all', channelUrl: 'All', channelName: 'All' },
        ...res.data,
      ])
    })
  }, [])

  useEffect(() => {
    if (isRefresh) {
      setIsLoading(true)
      makeCall('GET', 'webhooks/conversions').then((res) => {
        if (res.status === 200) {
          const data = res.data.map((row: any) => {
            if (!row.channel.channelUuid) {
              return {
                ...row,
                channel: {
                  channelUuid: '',
                  channelUrl: 'All',
                  channelName: 'All',
                },
              }
            }
            return row
          })
          setData(data)
          setIsRefresh(false)
          setIsLoading(false)
        }
      })
    }
  }, [isRefresh])

  const handleSort = (newSort: string) => {
    if (data) {
      let newSortDirection: 'asc' | 'desc' = 'asc'
      if (sortBy === newSort) {
        newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
      }
      const sortedData = orderBy(data, newSort, newSortDirection)
      setSortBy(newSort)
      setSortDirection(newSortDirection)
      setData(sortedData)
    }
  }

  const getRecord = async (uuid: string) => {
    setIsLoading(true)
    const response = await makeCall('GET', `webhooks/conversions/${uuid}`)
    if (response.status === 200) {
      setRecord(response.data)
    }
    setIsLoading(false)
    setIsCreate(true)
  }

  const getSearchedData = () => {
    if (searchVal && data.length) {
      let searchedData = data
      searchedData = searchedData.filter(
        (row) =>
          row.channel.channelName
            .toString()
            .toLowerCase()
            .includes(searchVal) ||
          row.webhookConversion.webhookConversionUrl
            .toString()
            .toLowerCase()
            .includes(searchVal) ||
          row.webhookConversion.webhookConversionMethod
            .toString()
            .toLowerCase()
            .includes(searchVal)
      )
      return searchedData
    }
    return data
  }

  const handleClose = () => {
    setIsCreate(false)
    setRecord(null)
  }

  return (
    <>
      <Loader loading={isLoading} />
      <Create
        isOpen={isCreate}
        handleClose={handleClose}
        refresh={() => setIsRefresh(true)}
        channels={channels}
        record={record}
      />
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <Grid container spacing={0} justify="space-between" alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h5" data-testid="page-heading">
              Conversion webhooks
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setIsCreate(true)}
            >
              Create New
            </Button>
          </Grid>
        </Grid>
        <DataTable
          data={getSearchedData()}
          pageCount={0}
          currentPage={0}
          handlePageChange={() => null}
          isLoading={isLoading}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          handleSearch={setSearchVal}
          handleAction={getRecord}
        />
      </Container>
    </>
  )
}
