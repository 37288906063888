import React, { useState, useEffect } from 'react'
import {
  TableCell,
  TableRow,
  Typography,
  Avatar,
  LinearProgress,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableHead,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { makeStyles } from '@material-ui/core/styles'
import TextTruncate from 'react-text-truncate'
import { AdvertiserType } from './types'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
// import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeCall } from '../../utils/api'
import { ChannelType } from './types'

const useStyles = makeStyles({
  tableCell: {
    borderBottom: 'none',
    color: '#282E67',
  },

  running: {
    backgroundColor: '#78d4c0',
    width: '10px',
    height: '10px',
    color: '#78d4c0',
  },
  approved: {
    backgroundColor: '#02637d',
    width: '10px',
    height: '10px',
    color: '#02637d',
  },
  pending: {
    backgroundColor: '#ffcf5f',
    width: '10px',
    height: '10px',
    color: '#ffcf5f',
  },
  rejected: {
    backgroundColor: '#ebeff4',
    width: '10px',
    height: '10px',
    color: '#ebeff4',
  },
  statusCode: {
    fontSize: '10px',
    marginLeft: '10px',
    textTransform: 'capitalize',
    letterSpacing: '0.6px',
  },
  progressRoot: {
    height: 4,
    borderRadius: 6,
  },
  progressRootColorPrimary: {
    backgroundColor: '#ebeff4',
  },
  progressBar: {
    borderRadius: 6,
    backgroundColor: '#78d4c0',
  },
})

type Props = {
  row: AdvertiserType
  idx: number
}

export default function AdvertiserRow({ row, idx }: Props) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const [channels, setChannels] = useState<ChannelType[] | null>(null)

  useEffect(() => {
    const getData = async (id: string) => {
      const url = `advertisers/${id}`
      const { data } = await makeCall('GET', url)
      setChannels(data.channels)
    }
    if (open && !channels) {
      getData(row.advertiserUuid)
    }
  }, [open, row.advertiserUuid, channels])

  const getStatusCodeAvatar = (code: string) => {
    let elem = <Avatar className={classes.rejected}>*</Avatar>
    switch (code) {
      case 'running':
        elem = <Avatar className={classes.running}>*</Avatar>
        break
      case 'approved':
        elem = <Avatar className={classes.approved}>*</Avatar>
        break
      case 'pending':
        elem = <Avatar className={classes.pending}>*</Avatar>
        break
      default:
        break
    }
    return elem
  }

  const formatter = new Intl.NumberFormat('da-DK')

  return (
    <>
      <TableRow
        hover
        style={
          idx % 2
            ? { background: 'rgba(0, 0, 150, 0.025)' }
            : { background: 'white' }
        }
      >
        <TableCell className={classes.tableCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {getStatusCodeAvatar(row.advertiserStatusCode)}
            <Typography className={classes.statusCode} color="primary">
              {row.advertiserStatusCode}
            </Typography>
          </div>
        </TableCell>
        <TableCell className={classes.tableCell} width={450}>
          <TextTruncate line={1} text={row.advertiserName} />
        </TableCell>
        <TableCell className={classes.tableCell} width={200}>
          <TextTruncate line={1} text={row.advertiserCountryName} />
        </TableCell>
        <TableCell className={classes.tableCell} width={200}>
          <TextTruncate
            line={1}
            text={`${
              row.advertiserPayout.maxPayoutType !== 'CPS'
                ? row.advertiserPayout.maxPayoutCurrencyCode
                : ''
            } ${formatter.format(row.advertiserPayout.maxPayoutValue)} ${
              row.advertiserPayout.maxPayoutType === 'CPS' ? '%' : ''
            }`}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Box display="flex" alignItems="center">
            <Box minWidth={35}>
              <Typography
                variant="body2"
                color="primary"
                style={{ fontSize: '12px' }}
              >{`${Math.round(row.advertiserIndex)}%`}</Typography>
            </Box>
            <Box width="100%" mr={1}>
              <LinearProgress
                variant="determinate"
                value={row.advertiserIndex}
                classes={{
                  root: classes.progressRoot,
                  colorPrimary: classes.progressRootColorPrimary,
                  bar: classes.progressBar,
                }}
              />
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'none' }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto">
            <Box marginBottom={2} marginTop={2}>
              <Typography
                variant="subtitle2"
                color="primary"
                gutterBottom
                component="div"
              >
                Channels
              </Typography>
              {channels &&
                channels.map((channel: ChannelType) => (
                  <Accordion key={channel.channelUuid}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="subtitle2" color="primary">
                        {channel.channelName}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {channel.campaigns.campaigns.length && (
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography variant="body2" color="primary">
                                  Status
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2" color="primary">
                                  Name
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2" color="primary">
                                  Country
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2" color="primary">
                                  Commission (up to)
                                </Typography>
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {channel.campaigns.campaigns.map((campaign) => (
                              <TableRow key={campaign.campaignUuid}>
                                <TableCell width={150}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {getStatusCodeAvatar(
                                      campaign.campaignStatusCode
                                    )}
                                    <Typography
                                      className={classes.statusCode}
                                      color="primary"
                                    >
                                      {campaign.campaignStatusCode}
                                    </Typography>
                                  </div>
                                </TableCell>
                                <TableCell color="primary" width={450}>
                                  <Typography variant="body2" color="primary">
                                    <TextTruncate
                                      line={1}
                                      text={campaign.campaignName}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2" color="primary">
                                    {campaign.campaignCountryName}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2" color="primary">
                                    {`${campaign.campaignPayout.maxPayoutCurrencyCode} ${campaign.campaignPayout.maxPayoutValue}`}
                                  </Typography>
                                </TableCell>
                                <TableCell />
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
