import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core/'
import { colors } from '../../colors'
import Card from './SingleCard'
import { CardDataType } from '../../types'

const useStyles = makeStyles({
  root: {
    marginTop: '22px',
  },
})

type Props = {
  data: CardDataType
}

export default function CardContainer({ data }: Props) {
  const classes = useStyles()
  return (
    <Grid className={classes.root} container spacing={3}>
      <Card
        icon="pageviews"
        title="Pageviews"
        value={data.pageviews}
        avatarColor={colors.pageviews.primary}
        avatarBackground={colors.pageviews.secondary}
        subTxt="EPM"
        subValue={data.epm}
        tooltip={
          <span>
            <div>Earnings Per Mille (Thousand)</div>
            <span style={{ color: colors.commission.primary }}>
              {data.commission}
            </span>{' '}
            /
            <span style={{ color: colors.pageviews.primary }}>
              {data.pageviews}
            </span>{' '}
            = {data.epm}
          </span>
        }
      />
      <Card
        icon="mouse"
        title="Clicks"
        value={data.clicks}
        avatarColor={colors.click.primary}
        avatarBackground={colors.click.secondary}
        subTxt="CTR"
        subValue={data.ctr}
        tooltip={
          <span>
            <div>Click Through Rate</div>
            <span style={{ color: colors.click.primary }}>{data.clicks}</span> /
            <span style={{ color: colors.pageviews.primary }}>
              {data.pageviews}
            </span>{' '}
            = {data.ctr}
          </span>
        }
      />
      <Card
        icon="compare_arrows"
        title="Conversions"
        value={data.conversions}
        avatarColor={colors.conversion.primary}
        avatarBackground={colors.conversion.secondary}
        subTxt="CR"
        subValue={data.cr}
        tooltip={
          <span>
            <div>Conversion Rate</div>
            <span style={{ color: colors.conversion.primary }}>
              {data.conversions}
            </span>{' '}
            /<span style={{ color: colors.click.primary }}>{data.clicks}</span>{' '}
            ={data.cr}
          </span>
        }
      />
      <Card
        icon="attach_money"
        title="Commission"
        value={data.commission}
        avatarColor={colors.commission.primary}
        avatarBackground={colors.commission.secondary}
        subTxt="EPC"
        subValue={data.epc}
        tooltip={
          <span>
            <div>Earnings Per Click</div>
            <span style={{ color: colors.commission.primary }}>
              {data.commission}
            </span>{' '}
            /<span style={{ color: colors.click.primary }}>{data.clicks}</span>{' '}
            = {data.epc}
          </span>
        }
      />
    </Grid>
  )
}
