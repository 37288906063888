import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Switch,
  Divider,
  FormControlLabel,
  Typography,
  Paper,
  Chip,
  InputAdornment,
  MenuItem,
} from '@material-ui/core'
import { createDefaultValues, defaultParameterOptions } from './initialValues'
import { BaseModalType, BaseFormType } from './types'
import { makeCall } from '../../../utils/api'
import Tooltip from './Tooltip'
import { v4 as uuidv4 } from 'uuid'
import JsonEditor from './JsonEditor'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

export default function Create({
  isOpen,
  handleClose,
  refresh,
  channels,
  record,
}: BaseModalType) {
  const [parameterOptions, setParameterOptions] = useState(
    defaultParameterOptions
  )
  const [parameters, setParameters] = useState([
    { key: 'commission', value: '{conversionCommission}', id: uuidv4() },
    { key: 'currency', value: '{conversionCurrencyCode}', id: uuidv4() },
  ])

  const [testResponse, setTestResponse] = useState<any>({})

  const [uuid, setUuid] = useState('')

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
    setValue,
    watch,
    getValues,
  } = useForm<BaseFormType>({ defaultValues: createDefaultValues })

  const resetForm = () => {
    reset(createDefaultValues)
    setUuid('')
    setParameters([
      { key: 'commission', value: '{conversionCommission}', id: uuidv4() },
      { key: 'currency', value: '{conversionCurrencyCode}', id: uuidv4() },
    ])
    setTestResponse({})
    handleClose()
  }

  const onSubmit = async (data: BaseFormType) => {
    console.log(data)
    const payload = {
      webhookConversionStatus: data.webhookConversionStatus,
      webhookConversionSendUpdates: data.webhookConversionSendUpdates,
      webhookConversionHeaderValue: data.webhookConversionHeaderValue,
      webhookConversionHeaderKey: data.webhookConversionHeaderKey,
      webhookConversionMethod: data.webhookConversionMethod,
      webhookConversionUrl: data.webhookConversionUrl,
      webhookConversionChannelUuid:
        data.webhookConversionChannelUid === 'all'
          ? ''
          : data.webhookConversionChannelUid,
      webhookConversionParams: data.webhookConversionParams
        ? `?${data.webhookConversionParams}`
        : '',
      webhookConversionJsonBody: data.webhookConversionJsonBody
        ? JSON.parse(data.webhookConversionJsonBody)
        : {},
    }

    console.log(payload)
    if (uuid) {
      await makeCall('PUT', `webhooks/conversions/${uuid}`, payload)
      refresh()
      return
    }

    const response = await makeCall('POST', 'webhooks/conversions', payload)
    console.log(response)
    setUuid(response.data)
    refresh()
  }

  const handleAddParameter = () => {
    const newParams = [...parameters, { key: '', value: '', id: uuidv4() }]
    setParameters(newParams)
  }

  const handleRemoveParameter = (parameter: {
    key: string
    value: string
    id: string
  }) => {
    const newParameters = parameters.filter((row) => row.id !== parameter.id)
    const reducedParams = newParameters.reduce((prev, current) => {
      return `${prev}${prev ? '&' : ''}${current.key}=${current.value}`
    }, '')
    setValue('webhookConversionParams', reducedParams)
    setParameters(newParameters)
  }

  const handleChangeParameter = (type: string, value: any, id: string) => {
    const newValue = value ? value : ''
    const newParams = parameters.map((row) => {
      if (row.id === id) {
        return {
          id: row.id,
          key: type === 'key' ? newValue : row.key,
          value: type === 'value' ? newValue : row.value,
        }
      }
      return row
    })
    const reducedParams = newParams.reduce((prev, current) => {
      return `${prev}${prev ? '&' : ''}${current.key}=${current.value}`
    }, '')
    setValue('webhookConversionParams', reducedParams)
    setParameters(newParams)
  }

  const handleBlurParams = (str: string) => {
    if (str) {
      const params = str.split('&')
      const newParams = params.map((row) => {
        const [key, value] = row.split('=')
        const filteredParam = parameters.filter(
          (paramRow) => paramRow.key === key
        )[0]
        if (
          parameterOptions.findIndex((optionRow) => optionRow.key === value) ===
          -1
        ) {
          const newOptions = [
            ...parameterOptions,
            { key: value, value: `Custom: ${value}` },
          ]
          setParameterOptions(newOptions)
        }
        if (filteredParam) {
          return {
            key,
            value,
            id: filteredParam.id,
          }
        }
        return {
          key,
          value,
          id: uuidv4(),
        }
      })
      setParameters(newParams)
    } else {
      setParameters([])
    }
  }

  const handleJsonChange = (payload: any) => {
    if (payload.error) {
      setValue('webhookConversionJsonBody', '')
      return
    }
    setValue('webhookConversionJsonBody', payload.json)
  }

  const handleTestClick = async () => {
    const values = getValues()
    const json = values.webhookConversionJsonBody
      ? JSON.parse(values.webhookConversionJsonBody)
      : {}
    const payload = {
      webhookConversionHeaderValue: values.webhookConversionHeaderValue,
      webhookConversionHeaderKey: values.webhookConversionHeaderKey,
      webhookConversionMethod: values.webhookConversionMethod,
      webhookConversionUrl: values.webhookConversionUrl,
      webhookConversionParams: values.webhookConversionParams
        ? `?${values.webhookConversionParams}`
        : '',
      webhookConversionJsonBody: json,
    }
    const response = await makeCall(
      'POST',
      'webhooks/conversions/test',
      payload
    )
    setTestResponse(response.data)
    console.log(values)
  }

  useEffect(() => {
    if (record) {
      reset({
        webhookConversionChannelUid: record.channel.channelUuid
          ? record.channel.channelUuid
          : 'all',
        webhookConversionStatus:
          record.webhookConversion.webhookConversionStatus,
        webhookConversionSendUpdates:
          record.webhookConversion.webhookConversionSendUpdates,
        webhookConversionJsonBody: record.webhookConversion
          .webhookConversionJsonBody
          ? JSON.stringify(record.webhookConversion.webhookConversionJsonBody)
          : '',
        webhookConversionHeaderValue:
          record.webhookConversion.webhookConversionHeaderValue,
        webhookConversionHeaderKey:
          record.webhookConversion.webhookConversionHeaderKey,
        webhookConversionParams: record.webhookConversion.webhookConversionParams.slice(
          1
        ),
        webhookConversionMethod:
          record.webhookConversion.webhookConversionMethod,
        webhookConversionUrl: record.webhookConversion.webhookConversionUrl,
      })
      handleBlurParams(record.webhookConversion.webhookConversionParams)
      setUuid(record.webhookConversion.webhookConversionUuid)
      setTestResponse({})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, reset])

  const currentMethod = watch('webhookConversionMethod')

  return (
    <>
      <Dialog
        open={isOpen}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={resetForm}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Conversion webhook editor
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ marginBottom: 20 }}>
            <Grid container spacing={5} style={{ marginBottom: '0px' }}>
              <Grid container item xs={5} spacing={1}>
                <Grid item xs={10}>
                  <Controller
                    name="webhookConversionChannelUid"
                    control={control}
                    rules={{ required: true }}
                    render={(field) => (
                      <TextField
                        {...field}
                        select
                        label="Channel"
                        variant="outlined"
                        fullWidth
                      >
                        {channels.map((row) => (
                          <MenuItem value={row.channelUuid}>
                            {row.channelName}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Tooltip text="If a specific channel is selected, only conversion from that channel are sent through this webhook." />
              </Grid>
              <Grid item xs={4} container>
                <Grid item xs={8}>
                  <Controller
                    name="webhookConversionSendUpdates"
                    control={control}
                    render={(field) => (
                      <FormControlLabel
                        style={{ marginTop: '6px' }}
                        control={
                          <Switch
                            onChange={(e) =>
                              setValue(
                                'webhookConversionSendUpdates',
                                e.target.checked
                              )
                            }
                            defaultChecked={
                              record &&
                              record.webhookConversion
                                .webhookConversionSendUpdates
                                ? true
                                : false
                            }
                            disabled={false}
                            color="primary"
                          />
                        }
                        label="Send conversion updates"
                      />
                    )}
                  />
                </Grid>
                <Tooltip
                  text={
                    <span>
                      If send conversion updates is set to YES, this webhook
                      will be executed when a conversion is{' '}
                      <strong>created</strong>, <strong>updated</strong>,{' '}
                      <strong>rejected</strong>, or <strong>paid</strong>.
                      Otherwise the webhook is only executed on the{' '}
                      <strong>created</strong> event. See more information at
                      the bottom of this page.
                    </span>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="webhookConversionStatus"
                  control={control}
                  render={(field) => (
                    <FormControlLabel
                      style={{ marginTop: '6px' }}
                      control={
                        <Switch
                          onChange={(e) =>
                            setValue(
                              'webhookConversionStatus',
                              e.target.checked
                            )
                          }
                          defaultChecked={
                            record &&
                            record.webhookConversion.webhookConversionStatus
                              ? true
                              : false
                          }
                          disabled={false}
                          color="primary"
                        />
                      }
                      label="Webhook active status"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ marginTop: '20px' }}>
                  Webhook Data
                </Typography>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={4}>
                  <Controller
                    name="webhookConversionMethod"
                    control={control}
                    rules={{ required: true }}
                    render={(field) => (
                      <TextField
                        {...field}
                        select
                        label="Method"
                        variant="outlined"
                        fullWidth
                      >
                        <MenuItem value="GET">GET</MenuItem>
                        <MenuItem value="POST">POST</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Tooltip text="If a specific channel is selected, only conversion from that channel are sent through this webhook." />
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={11}>
                  <Controller
                    name="webhookConversionUrl"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={(field) => (
                      <TextField
                        {...field}
                        error={errors.webhookConversionUrl ? true : false}
                        helperText={
                          errors.webhookConversionUrl
                            ? 'Webhook target URL is required!'
                            : null
                        }
                        margin="dense"
                        variant="outlined"
                        label="Webhook target URL"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Tooltip
                  col={1}
                  text="Must be a valid URL, with correct protocols. On every webhook execution, a request is set to this URL. Data tags are supported, see information at the bottom of this page."
                />
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={11}>
                  <Controller
                    name="webhookConversionParams"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={(field) => (
                      <TextField
                        {...field}
                        error={errors.webhookConversionParams ? true : false}
                        onBlur={(e) => handleBlurParams(e.target.value)}
                        helperText={
                          errors.webhookConversionParams
                            ? 'Parameters is required!'
                            : null
                        }
                        variant="outlined"
                        margin="dense"
                        label="Parameters"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">?</InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Tooltip
                  col={1}
                  text="Must be a valid URL, with correct protocols. On every webhook execution, a request is set to this URL. Data tags are supported, see information at the bottom of this page."
                />
              </Grid>
              <Grid item xs={12} container spacing={3}>
                {parameters.map((row) => {
                  return (
                    <React.Fragment key={row.id}>
                      <Grid item xs={5}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          label="Key"
                          defaultValue={row.key}
                          fullWidth
                          onChange={(e) =>
                            handleChangeParameter('key', e.target.value, row.id)
                          }
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Autocomplete
                          id="param-select"
                          options={parameterOptions}
                          getOptionLabel={(option) => option.value}
                          value={
                            parameterOptions.filter(
                              (option) => option.key === row.value
                            )[0]
                          }
                          fullWidth={false}
                          onChange={(e, values) =>
                            handleChangeParameter('value', values?.key, row.id)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Value"
                              variant="outlined"
                              margin="dense"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          style={{ marginTop: '10px' }}
                          variant="contained"
                          color="secondary"
                          type="button"
                          onClick={() => handleRemoveParameter(row)}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </React.Fragment>
                  )
                })}
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={handleAddParameter}
                >
                  Add parameter
                </Button>
              </Grid>
            </Grid>
            <Controller
              name="webhookConversionJsonBody"
              control={control}
              render={(field) => (
                <TextField
                  {...field}
                  style={{ display: 'none' }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              )}
            />
            {currentMethod === 'POST' && (
              <>
                <Divider style={{ margin: '20px 0' }} />
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <Typography
                      variant="h6"
                      style={{ display: 'inline-block' }}
                    >
                      JSON body
                    </Typography>
                    <Tooltip
                      col={1}
                      text="Must be a valid JSON object. Data tags are supported, see more information at the bottom of this page."
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6">Available data tags</Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <JsonEditor
                      placeholder={
                        record &&
                        record.webhookConversion.webhookConversionJsonBody
                          ? record.webhookConversion.webhookConversionJsonBody
                          : {}
                      }
                      height="348px"
                      viewOnly={false}
                      handleChange={(payload) => handleJsonChange(payload)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Paper variant="outlined" style={{ padding: '10px 10px' }}>
                      {defaultParameterOptions.map((row) => (
                        <Chip
                          style={{ margin: '11px 6px' }}
                          variant="outlined"
                          key={row.key}
                          label={
                            <Typography variant="subtitle2" color="primary">
                              {row.value}
                            </Typography>
                          }
                          color="primary"
                        />
                      ))}
                    </Paper>
                  </Grid>
                </Grid>
              </>
            )}
            <Divider style={{ margin: '20px 0' }} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ display: 'inline-block' }}>
                  Headers
                </Typography>
                <Tooltip
                  col={1}
                  text="Headers can be used for security, such as an API key. Both fields must have values in order to be populated in the webhook execution request. The value is encrypted being stored encrypted with Heylink."
                />
              </Grid>
              <Grid xs={4} item>
                <Controller
                  name="webhookConversionHeaderKey"
                  control={control}
                  render={(field) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="dense"
                      label="Key"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid xs={8} item>
                <Controller
                  name="webhookConversionHeaderValue"
                  control={control}
                  render={(field) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="dense"
                      label="Value"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
                <Tooltip
                  text="Changes may take upto 10 minutes before having effect."
                  col={1}
                />
              </Grid>
            </Grid>
            <Divider style={{ margin: '30px 0' }} />
            <Accordion variant="outlined">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={handleTestClick}
                    >
                      Test
                    </Button>
                    <Tooltip
                      text="Executes a webhook with the settings, and populates random conversion tag values. Use this for testing and debugging."
                      col={1}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6">Test results</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <JsonEditor placeholder={testResponse} height="500px" />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Divider style={{ margin: '30px 0' }} />
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: '30px' }}>
                <Typography variant="h5">Help section</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <Typography variant="h6">Conversion data</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <Typography variant="body1">
                  The webhook is customizable in such a way, that the partner
                  can define it's own schema/keys for the values. It is done by
                  inserting {} tags into either, conversionWebhookJsonBody,
                  conversionWebhookUrl or conversionWebhookParameters. Those
                  tags are then replaced (when valid), with the corresponding
                  conversion value.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <Typography variant="h6">JSON body</Typography>
              </Grid>
              <Grid item xs={12}>
                <JsonEditor
                  placeholder={{
                    sale: '{conversionSale}',
                    sub_id: '{conversionSubId}',
                    earnings: {
                      commission: '{conversionCommission}',
                      currency_code: '{conversionCurrencyCode}',
                    },
                    custom: 'data',
                  }}
                  height="200px"
                />
              </Grid>
              <Grid item xs={12} style={{ margin: '10px 0' }}>
                <Typography variant="subtitle2">Transforms into</Typography>
              </Grid>
              <Grid item xs={12}>
                <JsonEditor
                  placeholder={{
                    sale: 1234.56,
                    sub_id: 'some-click-sub-id',
                    earnings: {
                      commission: 123.45,
                      currency_code: 'USD',
                    },
                    custom: 'data',
                  }}
                  height="200px"
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: '30px', marginBottom: '10px' }}
              >
                <Typography variant="h6">Target url</Typography>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    backgroundColor: '#183055',
                    color: '#71a7ff',
                    padding: '25px 20px',
                    fontSize: '14px',
                  }}
                >
                  <pre>{`"https://domain.com/heylink-callback/{conversionChannelUuid}"`}</pre>
                  <br />
                  <pre style={{ color: '#9daab6' }}>{`// Transforms into`}</pre>
                  <br />
                  <pre>{`"https://domain.com/heylink-callback/8f36cfcd-e5f8-49ba-801d-a971233a45f3"`}</pre>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: '30px', marginBottom: '10px' }}
              >
                <Typography variant="h6">Parameters</Typography>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    backgroundColor: '#183055',
                    color: '#71a7ff',
                    padding: '25px 20px',
                    fontSize: '14px',
                  }}
                >
                  <pre>{`"?sale={conversionSale}&currency={conversionCurrencyCode}"`}</pre>
                  <br />
                  <pre style={{ color: '#9daab6' }}>{`// Transforms into`}</pre>
                  <br />
                  <pre>{`"?sale=1234.56&currency=USD"`}</pre>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: '40px', marginBottom: '10px' }}
              >
                <Typography variant="h6">Available conversion tags</Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontSize: '16px' }}>Tag</TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          Values
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          Type
                        </TableCell>
                        <TableCell
                          style={{ fontSize: '16px' }}
                          align="right"
                          width={500}
                        >
                          Description
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{ fontSize: '16px' }}
                        >{`{conversionUuid}`}</TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          5c33a6a2-b874-4c48-aefb-31328933bd3b
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          string
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          The UUID of the specific conversion.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ fontSize: '16px' }}
                        >{`{conversionClickUuid}`}</TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          5cad8c84-7f75-4edb-8c3b-677c58709762
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          string
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          The UUID of the click which generated this conversion.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ fontSize: '16px' }}
                        >{`{conversionAdvertiserUuid}`}</TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          6de8fff5-6197-4586-a8e0-a2e9106fa0d4
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          string
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          The UUID of the advertiser which generated this
                          conversion.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ fontSize: '16px' }}
                        >{`{conversionChannelUuid}`}</TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          304e40c5-ecdd-4595-8f89-e4569f7ec7c9
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          string
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          The UUID of the channel which generated this
                          conversion.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ fontSize: '16px' }}
                        >{`{conversionCommission}`}</TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          1234567.001
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          number
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          The commission which the partner earned from this
                          conversion. This value can, in rare cases, be
                          negative.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ fontSize: '16px' }}
                        >{`{conversionCurrencyCode}`}</TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          DKK
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          string
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          Currency code in the ISO 4217 format (3 uppercase
                          letters). This is applicate to both commission & sale.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ fontSize: '16px' }}
                        >{`{conversionSale}`}</TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          1234567.001
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          number
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          The total sale value of the order. If not available, 0
                          is sent as default.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ fontSize: '16px' }}
                        >{`{conversionSaleOrderId}`}</TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          advertiser-orderId-1234
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          string
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          The order id generated in from the advertiser's
                          system. Defaults to an empty string.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ fontSize: '16px' }}
                        >{`{conversionStatusKey}`}</TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          pending, paid, rejected
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          string
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          Explains the current status of the conversion. Once a
                          conversion is in the paid status, the conversion has
                          been paid to the partner, and can not change status
                          again. If it is later rejected, or part of the
                          underlaying order is rejected, a new conversion with
                          the same click UUID & order id will be created with
                          commission in negative value.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ fontSize: '16px' }}
                        >{`{conversionCreatedDatetime}`}</TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          YYYY-MM-DD HH:mm:ss
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          string
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          Defaults to Europe/Copenhagen timezone
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ fontSize: '16px' }}
                        >{`{conversionSubId}`}</TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          yourUniqueSubId
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          string
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }} align="right">
                          The optional subId which was set on the click which
                          generated this conversion. Defaults to an empty
                          string.
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginTop: '40px', marginBottom: '10px' }}
              >
                <Typography variant="h6" style={{ marginBottom: '10px' }}>
                  Conversion webhook executable events
                </Typography>
                <Typography variant="body1">
                  If conversionSendUpdates is set to true , a webhook will be
                  executed when a conversion is created, updated, rejected or
                  paid. Otherwise a webhook is only executed on the created
                  event.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontSize: '16px' }}>
                          Event
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }}>
                          Description
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ fontSize: '16px' }}>
                          created
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }}>
                          The advertiser has notified Heylink that a
                          sale/conversion has happened, and a corresponding
                          conversion has been created.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontSize: '16px' }}>
                          updated
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }}>
                          An update to a conversion can have many reasons, such
                          as attaching more meta-data. Not necessarily all
                          updated fields are available to the partner. The
                          webhook is still executed.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontSize: '16px' }}>
                          rejected
                        </TableCell>
                        <TableCell style={{ fontSize: '16px' }}>
                          The advertiser has notified Heylink that a
                          sale/conversion has been rejected, and as such the
                          status of the conversion has been updated.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontSize: '16px' }}>paid</TableCell>
                        <TableCell style={{ fontSize: '16px' }}>
                          The conversion has been attached to a payment-note,
                          and is being paid to the partner.
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
