import React from 'react'
import {
  Paper,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  makeStyles,
  useTheme,
  Theme,
  Chip,
} from '@material-ui/core/'
import {
  BarChart,
  Bar,
  Tooltip,
  YAxis,
  XAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import Skeleton from '@material-ui/lab/Skeleton'
import { DataType } from '../types'
import { colors } from '../../../../elements/colors'

type Props = {
  data: DataType | null
  isLoading: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 20,
  },
  paperContainer: {
    padding: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(3),
  },
}))

export default function ChartContainer({ data, isLoading }: Props) {
  const classes = useStyles()
  const theme = useTheme()

  const renderTooltip = (props: any) => {
    if (typeof props.payload != 'undefined' && props.payload != null) {
      if (typeof props.payload[0] != 'undefined') {
        const formatter = new Intl.NumberFormat('da-DK')
        return (
          <Card style={{ zIndex: 1000 }}>
            <CardContent>
              <Typography variant="subtitle1" color="primary">
                <b>{props.payload[0].payload.timeGrp}</b>
              </Typography>
              <Divider />
              <Typography
                variant="subtitle2"
                style={{
                  color: colors.click.primary,
                  marginTop: theme.spacing(2),
                }}
              >
                <Chip
                  size="small"
                  label="0"
                  style={{
                    backgroundColor: colors.click.primary,
                    color: colors.click.primary,
                    marginRight: 10,
                  }}
                />
                Pending:{' '}
                {formatter.format(
                  props.payload[0].payload.sums.pendingNoPaymentReceived
                )}{' '}
                {props.payload[0].payload.currencyCode}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: colors.conversion.primary,
                  marginTop: theme.spacing(2),
                }}
              >
                <Chip
                  size="small"
                  label="0"
                  style={{
                    backgroundColor: colors.conversion.primary,
                    color: colors.conversion.primary,
                    marginRight: 10,
                  }}
                />
                Ready for payout:{' '}
                {formatter.format(
                  props.payload[0].payload.sums.pendingPaymentReceived
                )}{' '}
                {props.payload[0].payload.currencyCode}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: theme.palette.secondary.main,
                  marginTop: theme.spacing(2),
                }}
              >
                <Chip
                  size="small"
                  label="0"
                  style={{
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.main,
                    marginRight: 10,
                  }}
                />
                Rejected:{' '}
                {formatter.format(props.payload[0].payload.sums.rejected)}{' '}
                {props.payload[0].payload.currencyCode}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: colors.commission.primary,
                  marginTop: theme.spacing(2),
                }}
              >
                <Chip
                  size="small"
                  label="0"
                  style={{
                    backgroundColor: colors.commission.primary,
                    color: colors.commission.primary,
                    marginRight: 10,
                  }}
                />
                Paid out: {formatter.format(props.payload[0].payload.sums.paid)}{' '}
                {props.payload[0].payload.currencyCode}
              </Typography>
              <Divider />
              <Typography
                variant="subtitle1"
                color="primary"
                style={{ marginTop: theme.spacing(2) }}
              >
                Conversions:{' '}
                <b>
                  {formatter.format(
                    props.payload[0].payload.counts.conversionsCount
                  )}
                </b>
              </Typography>
            </CardContent>
          </Card>
        )
      }
    }
  }

  const renderLabel = (props: any, rows: any) => {
    return (
      <text
        x={props.x}
        y={props.y + 5}
        dy={10}
        fill="#282E67"
        fontSize={10}
        fontFamily="Rubik"
        fontWeight="500"
        textAnchor="middle"
      >
        {props.payload.value}
      </text>
    )
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paperContainer} variant="outlined">
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={3} style={{ marginLeft: 3 }}>
                <Grid item xs={2}>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    style={{ fontSize: 13 }}
                  >
                    <Chip
                      size="small"
                      label="0"
                      style={{
                        backgroundColor: colors.click.primary,
                        color: colors.click.primary,
                        marginRight: 10,
                      }}
                    />
                    Pending
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    style={{ fontSize: 13 }}
                  >
                    <Chip
                      size="small"
                      label="0"
                      style={{
                        backgroundColor: colors.conversion.primary,
                        color: colors.conversion.primary,
                        marginRight: 10,
                      }}
                    />
                    Ready for payout
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    style={{ fontSize: 13 }}
                  >
                    <Chip
                      size="small"
                      label="0"
                      style={{
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.main,
                        marginRight: 10,
                      }}
                    />
                    Rejected
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    style={{ fontSize: 13 }}
                  >
                    <Chip
                      size="small"
                      label="0"
                      style={{
                        backgroundColor: colors.commission.primary,
                        color: colors.commission.primary,
                        marginRight: 10,
                      }}
                    />
                    Paid out
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.section}>
            {isLoading ? (
              <Skeleton
                variant="rect"
                width="100%"
                height={300}
                animation="wave"
              />
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={data?.bars}
                  margin={{
                    top: 7,
                    right: 7,
                    left: 7,
                    bottom: 1,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDasharray="5 3" />
                  <XAxis
                    dataKey="timeGrp"
                    tick={(props: any) => renderLabel(props, data)}
                    stroke={theme.palette.primary.main}
                    interval={0}
                  />
                  <YAxis hide allowDataOverflow={true} />
                  <Tooltip
                    cursor={{ fill: 'rgba(0, 0, 150, 0.024)' }}
                    content={(props: any) => renderTooltip(props)}
                  />
                  <Bar
                    dataKey="bar.paid"
                    fill={colors.commission.primary}
                    stroke={colors.commission.primary}
                    strokeWidth={0.9}
                    fillOpacity={0.9}
                    strokeOpacity={0.6}
                    stackId="a"
                    barSize={80}
                    maxBarSize={100}
                  />
                  <Bar
                    dataKey="bar.rejected"
                    fill={theme.palette.secondary.main}
                    stroke={theme.palette.secondary.main}
                    strokeWidth={0.9}
                    fillOpacity={0.9}
                    strokeOpacity={0.6}
                    stackId="a"
                    barSize={80}
                    maxBarSize={100}
                  />
                  <Bar
                    dataKey="bar.pendingPaymentReceived"
                    fill={colors.conversion.primary}
                    stroke={colors.conversion.primary}
                    strokeWidth={0.9}
                    fillOpacity={0.9}
                    strokeOpacity={0.6}
                    stackId="a"
                    barSize={80}
                    maxBarSize={100}
                  />
                  <Bar
                    dataKey="bar.pendingNoPaymentReceived"
                    fill={colors.click.primary}
                    stroke={colors.click.primary}
                    strokeWidth={0.9}
                    fillOpacity={0.9}
                    strokeOpacity={0.6}
                    stackId="a"
                    barSize={80}
                    maxBarSize={100}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
