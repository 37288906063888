import React, { useState, useEffect } from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import { Typography, Paper, Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { makeCall } from '../../../../utils/api'
import { colors } from '../../colors'

const useStyles = makeStyles({
  container: {
    backgroundColor: '#eee',
    padding: 12,
    width: 360,
    height: '100%',
  },
  timelineConnector: {
    backgroundColor: '#282E67',
    width: '2px',
    borderRadius: 5,
  },
  heading: {
    margin: '20px',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  timelinePaper: {
    width: '280px',
    padding: '10px',
    overflow: 'hidden',
  },
  timelineHeading: {
    marginBottom: 10,
  },
  timelineTxt: {
    fontSize: '12px',
    marginBottom: 10,
  },
  pill: {
    padding: '5px',
    fontWeight: 500,
    borderRadius: '5px',
  },
  commission: {
    color: colors.commission.primary,
    backgroundColor: colors.commission.secondary,
  },
})

type Props = {
  clickUuid: string
  setClickUuid: (val: string) => void
}

export default function BasicTimeline({ clickUuid, setClickUuid }: Props) {
  const classes = useStyles()
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    const getData = async () => {
      const url = `journeys/${clickUuid}`
      const { data } = await makeCall('GET', url)
      if (data.hasOwnProperty('code')) {
        return false
      } else {
        setData(data)
      }
    }
    if (clickUuid) {
      getData()
    }
  }, [clickUuid])
  const toggleDrawer = () => {
    setData(null)
    setClickUuid('')
  }
  return (
    <Drawer
      anchor="right"
      open={clickUuid ? true : false}
      onClose={toggleDrawer}
    >
      <Paper className={classes.container} elevation={0} variant="outlined">
        <Typography
          variant="subtitle1"
          color="primary"
          className={classes.heading}
        >
          User journey
        </Typography>
        {data ? (
          <Timeline style={{ marginLeft: '-30px' }}>
            {data && data.referer.refererUrl ? (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot variant="outlined" color="primary" />
                  <TimelineConnector
                    classes={{ root: classes.timelineConnector }}
                  />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper
                    className={classes.timelinePaper}
                    elevation={0}
                    variant="outlined"
                  >
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      className={classes.timelineHeading}
                    >
                      Referred from
                    </Typography>
                    <Typography variant="body1" className={classes.timelineTxt}>
                      {data.referer.refererUrl}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ) : null}
            {data && data.visit.pageUrl ? (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot variant="outlined" color="primary" />
                  <TimelineConnector
                    classes={{ root: classes.timelineConnector }}
                  />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper
                    className={classes.timelinePaper}
                    elevation={0}
                    variant="outlined"
                  >
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      className={classes.timelineHeading}
                    >
                      Visited
                    </Typography>
                    <Typography variant="body1" className={classes.timelineTxt}>
                      {data.visit.pageUrl}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ) : null}
            {data && data.click.clickTargetUrl ? (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot variant="outlined" color="primary" />
                  {data && data.conversion.commissionSum ? (
                    <TimelineConnector
                      classes={{ root: classes.timelineConnector }}
                    />
                  ) : null}
                </TimelineSeparator>
                <TimelineContent>
                  <Paper
                    className={classes.timelinePaper}
                    elevation={0}
                    variant="outlined"
                  >
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      className={classes.timelineHeading}
                    >
                      Clicked a link to
                    </Typography>
                    <Typography variant="body1" className={classes.timelineTxt}>
                      {data.click.clickDatetime}
                    </Typography>
                    <Typography variant="body1" className={classes.timelineTxt}>
                      {data.click.clickTargetUrl}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ) : null}
            {data && data.conversion.commissionSum ? (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot variant="outlined" color="primary" />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper
                    className={classes.timelinePaper}
                    elevation={0}
                    variant="outlined"
                  >
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      className={classes.timelineHeading}
                    >
                      Commission earned
                    </Typography>
                    <Typography variant="body1" className={classes.timelineTxt}>
                      {data.conversion.conversionDatetime}
                    </Typography>
                    <Typography variant="body1" className={classes.timelineTxt}>
                      <span
                        className={[classes.commission, classes.pill].join(' ')}
                      >
                        {data.conversion.currencyCode}{' '}
                        {data.conversion.commissionSum}
                      </span>
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ) : null}
          </Timeline>
        ) : null}
      </Paper>
    </Drawer>
  )
}
