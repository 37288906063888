import React, { Suspense, lazy } from 'react'
import { hot } from 'react-hot-loader'
import GlobalStyles from './elements/GlobalStyles'
import Header from './components/Header'
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom'
import routes from './routes'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Mukta", "Rubik", "Helvetica", "Arial", sans-serif;',
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#282E67',
    },
    secondary: {
      main: '#ff4081',
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        fontSize: '0.8rem',
      },
      footer: {
        left: 0,
        bottom: 0, // <-- KEY
        zIndex: 2,
        position: 'sticky',
        backgroundColor: '#fff',
      },
    },
  },
})

const Login = lazy(() => import('./screens/Login'))
const LoginAs = lazy(() => import('./screens/Login/LoginAs'))

function App() {
  const isAuth = localStorage.getItem('publisherAuthKey')
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <main>
        <Router>
          <Suspense fallback={<h1>&nbsp;</h1>}>
            {isAuth ? <Header /> : null}

            <Switch>
              <Route exact strict path="/login/" component={Login} />
              <Route exact strict path="/loginas/:token?" component={LoginAs} />
              {!isAuth && <Redirect to="/login/" />}
              {routes.map((route, i) => (
                <Route key={i} {...route} />
              ))}
            </Switch>
          </Suspense>
        </Router>
      </main>
    </ThemeProvider>
  )
}

export default hot(module)(App)
