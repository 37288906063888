import { AdvertisersType } from './types'

export const initialData: AdvertisersType = {
  advertisers: [],
  counts: {
    all: 0,
    isRecommended: 0,
    isTopEarner: 0,
    isExclusive: 0,
  },
}

export const statuses = [
  { title: 'Approved', value: 'approved' },
  { title: 'Running', value: 'running' },
  { title: 'Pending', value: 'pending' },
  { title: 'Rejected', value: 'rejected' },
]
