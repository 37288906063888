import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { CountriesType, StatusType, CountryType } from './types'
import { statuses } from './initialData'

type Props = {
  countries: CountriesType[]
  search: string
  status: StatusType
  country: CountryType
  setSearch: (val: string) => void
  setCountry: (val: CountryType) => void
  setStatus: (val: StatusType) => void
}

export default function Filters({
  countries,
  search,
  status,
  country,
  setSearch,
  setCountry,
  setStatus,
}: Props) {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const handleStatusChange = (
    event: React.ChangeEvent<{}>,
    value: StatusType
  ) => {
    setStatus(value)
  }

  const handleCountryChange = (
    event: React.ChangeEvent<{}>,
    value: CountryType
  ) => {
    setCountry(value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <TextField
          id="search-advertiser"
          label="Search"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          id="status-advertiser"
          options={statuses}
          getOptionLabel={(option) => option.title}
          value={status}
          onChange={handleStatusChange}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="Status" variant="outlined" />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          id="country-advertiser"
          options={countries}
          getOptionLabel={(option) => option.countryName}
          value={country}
          onChange={handleCountryChange}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="Country" variant="outlined" />
          )}
        />
      </Grid>
    </Grid>
  )
}
