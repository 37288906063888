import React from 'react'
import { Button } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../colors'

const useStyles = makeStyles({
  legendButton: {
    marginLeft: '8px',
  },
  btnPageview: {
    color: '#fff',
    backgroundColor: colors.pageviews.primary,
    '&:hover': {
      backgroundColor: colors.pageviews.primary,
    },
  },
  btnPageviewAlt: {
    color: colors.pageviews.primary,
  },
  btnClicks: {
    color: '#fff',
    backgroundColor: colors.click.primary,
    '&:hover': {
      backgroundColor: colors.click.primary,
    },
  },
  btnClicksAlt: {
    color: colors.click.primary,
  },
  btnConversions: {
    color: '#fff',
    backgroundColor: colors.conversion.primary,
    '&:hover': {
      backgroundColor: colors.conversion.primary,
    },
  },
  btnConversionsAlt: {
    color: colors.conversion.primary,
  },
  btnCommission: {
    color: '#fff',
    backgroundColor: colors.commission.primary,
    '&:hover': {
      backgroundColor: colors.commission.primary,
    },
  },
  btnCommissionAlt: {
    color: colors.commission.primary,
  },
})

type Props = {
  filters: string[]
  handleFilterClick: (key: string) => void
}

export default function Buttons({ filters, handleFilterClick }: Props) {
  const classes = useStyles()
  return (
    <div>
      <Button
        className={[
          classes.legendButton,
          filters.includes('pageviews')
            ? classes.btnPageviewAlt
            : classes.btnPageview,
        ].join(' ')}
        size="small"
        variant={filters.includes('pageviews') ? 'outlined' : 'contained'}
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => handleFilterClick('pageviews')}
      >
        Pageviews
      </Button>
      <Button
        className={[
          classes.legendButton,
          filters.includes('clicks') ? classes.btnClicksAlt : classes.btnClicks,
        ].join(' ')}
        size="small"
        variant={filters.includes('clicks') ? 'outlined' : 'contained'}
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => handleFilterClick('clicks')}
      >
        Clicks
      </Button>
      <Button
        className={[
          classes.legendButton,
          filters.includes('conversions')
            ? classes.btnConversionsAlt
            : classes.btnConversions,
        ].join(' ')}
        size="small"
        variant={filters.includes('conversions') ? 'outlined' : 'contained'}
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => handleFilterClick('conversions')}
      >
        Conversions
      </Button>
      <Button
        className={[
          classes.legendButton,
          filters.includes('commission')
            ? classes.btnCommissionAlt
            : classes.btnCommission,
        ].join(' ')}
        size="small"
        variant={filters.includes('commission') ? 'outlined' : 'contained'}
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => handleFilterClick('commission')}
      >
        Commission
      </Button>
    </div>
  )
}
