export const TimeGroup = [
  {
    key: 'month',
    value: 'Month',
  },
  {
    key: 'year',
    value: 'Year',
  },
]

function generateArrayOfYears() {
  var max = new Date().getFullYear()
  var min = max - 4
  var years = []

  for (var i = max; i >= min; i--) {
    years.push({
      key: i.toString(),
      value: i.toString(),
    })
  }
  return years
}

export const Years = generateArrayOfYears()
