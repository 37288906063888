import React from 'react'
import {
  Paper,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
} from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import { BarsGraphType } from '../../types'
import { colors } from '../../../../elements/colors'
import styled from 'styled-components'
import {
  BarChart,
  Bar,
  Tooltip,
  YAxis,
  XAxis,
  Text,
  ResponsiveContainer,
} from 'recharts'

const BarsContainer = styled.div`
  padding-top: 12px;
  /* padding-bottom: 16px; */
  .recharts-cartesian-axis-tick {
    font-size: 14px;
    font-family: Rubik, sans-serif;
  }
`

const useStyles = makeStyles({
  root: {
    marginTop: '22px',
    marginBottom: '22px',
  },
  paperContainer: {
    padding: '16px',
  },
})

type Props = {
  data: BarsGraphType[]
}

export default function BarChartContainer({ data }: Props) {
  const classes = useStyles()

  const renderTooltip = (props: any) => {
    if (typeof props.payload != 'undefined' && props.payload != null) {
      if (typeof props.payload[0] != 'undefined') {
        const formatter = new Intl.NumberFormat('da-DK')
        return (
          <Card style={{ zIndex: 1000 }}>
            <CardContent>
              <Typography variant="subtitle2">
                <b>{props.payload[0].payload.grpName}</b>
              </Typography>
              <Divider />
              <Typography style={{ color: colors.pageviews.primary }}>
                Pageviews:{' '}
                {formatter.format(props.payload[0].payload.pageviews)}
              </Typography>
              <Typography style={{ color: colors.click.primary }}>
                Clicks: {formatter.format(props.payload[0].payload.clicks)}
              </Typography>
              <Typography style={{ color: colors.conversion.primary }}>
                Conversions:{' '}
                {formatter.format(props.payload[0].payload.conversions)}
              </Typography>
              <Divider />
              <Typography style={{ color: colors.commission.primary }}>
                Commission:{' '}
                {window.localStorage.getItem('publisherCurrency') || 'DKK'}{' '}
                {formatter.format(props.payload[0].payload.commission)}
              </Typography>
            </CardContent>
          </Card>
        )
      }
    }
  }

  const renderLabel = (props: any, rows: any) => {
    return (
      <svg>
        <Text {...props}>{props.payload.value}</Text>
        {props.index !== 9 && (
          <image
            x={props.x - 12}
            y={props.y + 24}
            href={rows[props.index].favicon}
            width="24"
            height="24"
          />
        )}
      </svg>
    )
  }
  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <Paper className={classes.paperContainer} variant="outlined">
          <Grid justify="space-between" alignItems="center" container>
            <div>
              <Typography variant="subtitle2">Performance graph</Typography>
            </div>
          </Grid>
          <BarsContainer>
            <ResponsiveContainer width="100%" height={350}>
              <BarChart
                data={data}
                margin={{ top: 5, right: 0, left: 0, bottom: 26 }}
              >
                <Tooltip
                  content={(props: any) => renderTooltip(props)}
                  wrapperStyle={{ zIndex: 10000 }}
                />
                <YAxis hide />
                <YAxis yAxisId="clicks" hide allowDataOverflow={true} />
                <YAxis yAxisId="conversions" hide allowDataOverflow={true} />
                <YAxis yAxisId="commission" hide allowDataOverflow={true} />
                <XAxis
                  dataKey="grpName"
                  tick={(props: any) => renderLabel(props, data)}
                />
                <Bar
                  dataKey="clicks"
                  fill={colors.click.primary}
                  stackId="a"
                  yAxisId="clicks"
                />
                <Bar
                  dataKey="conversions"
                  fill={colors.conversion.primary}
                  stackId="a"
                  yAxisId="conversions"
                />
                <Bar
                  dataKey="commission"
                  fill={colors.commission.primary}
                  stackId="a"
                  yAxisId="commission"
                />
              </BarChart>
            </ResponsiveContainer>
          </BarsContainer>
        </Paper>
      </Grid>
    </Grid>
  )
}
