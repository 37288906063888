import React, { useState } from 'react'
import { ListItemText, ListItem, Icon } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import { NavHighlight } from '../../elements/colors'
import { makeStyles } from '@material-ui/core/styles'

export function hasChildren(item: any) {
  const { subNav: children } = item

  if (children === undefined) {
    return false
  }

  if (children.constructor !== Array) {
    return false
  }

  if (children.length === 0) {
    return false
  }

  return true
}

const useStyles = makeStyles((theme) => ({
  activeLink: {
    color: theme.palette.primary.main,
    background: '#3c4d6d',
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      display: 'block',
      backgroundColor: NavHighlight,
      width: '6px',
      borderStartStartRadius: '6px',
      borderEndStartRadius: '6px',
    },
  },
  text: {
    transform: 'translateY(1px)',
    '& .MuiTypography-body1': {
      fontWeight: 'bold',
    },
  },
  listItem: {
    color: '#fff',
    marginTop: '10px',
    position: 'relative',
    borderRadius: '6px',
    padding: '11px 36px',
    '&:hover': {
      backgroundColor: '#3c4d6d',
      color: 'white',
    },
    // '&$selected': {
    //   backgroundColor: 'red',
    //   color: 'white',
    // },
    // '&$selected:hover': {
    //   backgroundColor: 'purple',
    //   color: 'white',
    // },
    selected: {},
  },
}))

export function Menu({ item, currentTabIndex, handleListItemClick }: any) {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel
  return (
    <Component
      item={item}
      currentTabIndex={currentTabIndex}
      handleListItemClick={handleListItemClick}
    />
  )
}

const MultiLevel = ({ item, currentTabIndex, handleListItemClick }: any) => {
  const classes = useStyles()
  const { subNav: children } = item
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen((prev) => !prev)
  }
  return (
    <React.Fragment>
      <ListItem button onClick={handleClick} className={classes.listItem}>
        <Icon style={{ color: '#fff', marginRight: '18px' }}>
          {item.icon ? item.icon : null}
        </Icon>
        <ListItemText primary={item.text} className={classes.text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child: any) => (
            <SingleLevel
              key={child.id}
              item={child}
              currentTabIndex={currentTabIndex}
              handleListItemClick={handleListItemClick}
            />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  )
}

const SingleLevel = ({ item, currentTabIndex, handleListItemClick }: any) => {
  const classes = useStyles()
  return (
    <ListItem
      button
      key={item.id}
      className={[
        classes.listItem,
        currentTabIndex === item.id ? classes.activeLink : '',
      ].join(' ')}
      onClick={() => handleListItemClick(item)}
    >
      {/* <ListItemIcon style={{ color: '#fff' }}>
        {item.icon ? item.icon : null}
      </ListItemIcon> */}
      <Icon style={{ color: '#fff', marginRight: '18px' }}>
        {item.icon ? item.icon : null}
      </Icon>
      <ListItemText primary={item.text} className={classes.text} />
    </ListItem>
  )
}
