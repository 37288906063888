import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Avatar,
  Chip,
} from '@material-ui/core'
import TextTruncate from 'react-text-truncate'
import { makeStyles } from '@material-ui/core/styles'
import { ChannelDataType } from './types'

type Props = {
  channels: ChannelDataType[]
  sortBy: string
  sortDirection: 'asc' | 'desc'
  handleSort: (val: string) => void
  pageCount: number
}

const useStyles = makeStyles({
  table: {
    marginTop: '22px',
    maxHeight: '750px',
  },
  tableCell: {
    borderBottom: 'none',
    color: '#282E67',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  avatar: {
    height: 25,
    width: 25,
    fontSize: '1rem',
    marginRight: 5,
  },
})

export default function ChannelsTable({
  channels,
  sortBy,
  sortDirection,
  handleSort,
}: Props) {
  const classes = useStyles()

  return (
    <TableContainer className={classes.table}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortBy === 'channelName'}
                direction={sortBy === 'channelName' ? sortDirection : 'asc'}
                onClick={() => handleSort('channelName')}
              >
                <Typography variant="subtitle2" color="primary">
                  Channel
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell align="right">
              <TableSortLabel
                active={sortBy === 'channelTypeName'}
                direction={sortBy === 'channelTypeName' ? sortDirection : 'asc'}
                onClick={() => handleSort('channelTypeName')}
              >
                <Typography variant="subtitle2" color="primary">
                  Type
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell align="right">
              <TableSortLabel
                active={sortBy === 'channelIsActive'}
                direction={sortBy === 'channelIsActive' ? sortDirection : 'asc'}
                onClick={() => handleSort('channelIsActive')}
              >
                <Typography variant="subtitle2" color="primary">
                  Status
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell align="right">
              <TableSortLabel
                active={sortBy === 'channelAdvertiserCount'}
                direction={
                  sortBy === 'channelAdvertiserCount' ? sortDirection : 'asc'
                }
                onClick={() => handleSort('channelAdvertiserCount')}
              >
                <Typography variant="subtitle2" color="primary">
                  Advertisers
                </Typography>
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {channels.map((row, idx) => (
            <TableRow
              key={row.channelUuid}
              hover
              style={
                idx % 2
                  ? { background: 'rgba(0, 0, 150, 0.025)' }
                  : { background: 'white' }
              }
            >
              <TableCell className={classes.tableCell} width={600}>
                <div className={classes.avatarContainer}>
                  <Avatar
                    className={classes.avatar}
                    sizes="15"
                    alt={row.channelName}
                    src={row.channelFavicon}
                  >
                    {row.channelName.charAt(0).toUpperCase()}
                  </Avatar>
                  <div style={{ width: '325px' }}>
                    <TextTruncate line={1} text={row.channelName} />
                  </div>
                </div>
              </TableCell>
              <TableCell
                className={classes.tableCell}
                width={200}
                align="right"
              >
                <TextTruncate line={1} text={row.channelTypeName} />
              </TableCell>
              <TableCell
                className={classes.tableCell}
                width={200}
                align="right"
              >
                <Chip
                  size="small"
                  label={row.channelIsActive ? 'Active' : 'Inactive'}
                  color={row.channelIsActive ? 'primary' : 'secondary'}
                />
              </TableCell>
              <TableCell
                align="right"
                className={classes.tableCell}
                width={200}
              >
                {`${row.channelAdvertiserCount} advertisers`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
