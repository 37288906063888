import { BaseFormType } from './types'

export const createDefaultValues: BaseFormType = {
  webhookConversionStatus: false,
  webhookConversionSendUpdates: false,
  webhookConversionChannelUid: 'all',
  webhookConversionJsonBody: '',
  webhookConversionHeaderValue: '',
  webhookConversionHeaderKey: '',
  webhookConversionParams:
    'commission={conversionCommission}&currency={conversionCurrencyCode}',
  webhookConversionMethod: 'GET',
  webhookConversionUrl: 'https://postman-echo.com/post',
}

export const defaultParameterOptions = [
  { key: '{conversionUuid}', value: '{conversionUuid}' },
  { key: '{conversionClickUuid}', value: '{conversionClickUuid}' },
  { key: '{conversionAdvertiserUuid}', value: '{conversionAdvertiserUuid}' },
  { key: '{conversionChannelUuid}', value: '{conversionChannelUuid}' },
  { key: '{conversionCommission}', value: '{conversionCommission}' },
  { key: '{conversionCurrencyCode}', value: '{conversionCurrencyCode}' },
  { key: '{conversionSale}', value: '{conversionSale}' },
  { key: '{conversionSaleOrderId}', value: '{conversionSaleOrderId}' },
  { key: '{conversionStatusKey}', value: '{conversionStatusKey}' },
  { key: '{conversionCreatedDatetime}', value: '{conversionCreatedDatetime}' },
  { key: '{conversionSubId}', value: '{conversionSubId}' },
]
