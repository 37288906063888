import { Grid, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import Loader from '../../../components/Loader'
import { makeCall, generateFile } from '../../../utils/api'
import { Container } from '../style'
import { Filters } from './Filters'
import { Channel, Currency, FiltersType, DataType } from './types'
import CardsContainer from './CardsContainer'
import ChartContainer from './ChartContainer'
import Table from './Table'
import { orderBy } from 'lodash'

export default function StatusReport() {
  const [isLoading, setIsLoading] = useState(true)
  const [channels, setChannels] = useState<Channel[]>([])
  const [currencies, setCurrencies] = useState<Currency[]>([])
  const [data, setData] = useState<DataType | null>(null)
  const [sortBy, setSortBy] = useState('timeGrp')
  const [sortDirection, setSortDirection] = useState<'desc' | 'asc'>('asc')
  const [searchVal, setSearchVal] = useState('')
  const [filters, setFilters] = useState<FiltersType>({
    timeGroupBy: 'month',
    year: new Date().getFullYear(),
    filters: {},
  })

  useEffect(() => {
    const getData = () => {
      makeCall('POST', 'finance/reports/status', {
        timeGroupBy: 'month',
        year: new Date().getFullYear(),
        filters: {},
      }).then((res) => setData(res.data))
      makeCall('GET', 'channels').then((res) => setChannels(res.data))
      makeCall('GET', 'currencies').then((res) => setCurrencies(res.data))
      setIsLoading(false)
    }
    getData()
  }, [])

  const handleOnSubmit = async (payload: FiltersType) => {
    setIsLoading(true)
    const response = await makeCall('POST', 'finance/reports/status', payload)
    setData(response.data)
    setIsLoading(false)
    setSearchVal('')
    setSortDirection('asc')
    setSortBy('timeGrp')
    setFilters(payload)
  }

  const getSearchedData = () => {
    if (searchVal && data?.rows) {
      let searchedData = data.rows
      searchedData = searchedData.filter(
        (row) =>
          row.timeGrp.toString().toLowerCase().includes(searchVal) ||
          row.currencyCode.toLowerCase().includes(searchVal)
      )
      return { ...data, rows: searchedData }
    }
    return data
  }

  const handleSort = (newSort: string) => {
    if (data) {
      let newSortDirection: 'asc' | 'desc' = 'asc'
      if (sortBy === newSort) {
        newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
      }
      const sortedData = orderBy(data.rows, newSort, newSortDirection)
      setSortBy(newSort)
      setSortDirection(newSortDirection)
      setData({ ...data, rows: sortedData })
    }
  }

  const openAsCSV = async () => {
    if (data && data.rows.length && filters) {
      setIsLoading(true)
      const response = await makeCall(
        'POST',
        `finance/reports/status`,
        filters,
        'text/csv'
      )
      if (response.data) {
        generateFile(response.data, `finance-status-report.csv`)
      }
      setIsLoading(false)
    }
  }

  return (
    <>
      <Loader loading={isLoading} />
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <Grid container spacing={0} justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5" data-testid="page-heading">
              Finance status report
            </Typography>
          </Grid>
        </Grid>
        <Filters
          isLoading={isLoading}
          onSubmit={handleOnSubmit}
          channels={channels}
          currencies={currencies}
        />
        <CardsContainer isLoading={isLoading} data={data} />
        <ChartContainer isLoading={isLoading} data={data} />
        <Table
          data={getSearchedData()}
          isLoading={isLoading}
          pageCount={0}
          currentPage={0}
          handleSearch={setSearchVal}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          handlePageChange={() => null}
          handleCSVDownloadClick={openAsCSV}
        />
      </Container>
    </>
  )
}
