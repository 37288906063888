import React from 'react'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Theme,
  Icon,
} from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import SearchIcon from '@material-ui/icons/Search'
import Pagination from '@material-ui/lab/Pagination'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Data } from './types'

const useStyles = makeStyles({
  table: {
    maxHeight: '700px',
  },
  root: {
    marginTop: '50px',
    position: 'relative',
  },
  paperContainer: {
    padding: '16px',
    position: 'relative',
  },
  pill: {
    padding: '5px',
    fontWeight: 500,
    borderRadius: '5px',
  },
  tableCell: {
    borderBottom: 'none',
  },
  tableFooterCell: {
    borderBottom: 'none',
    fontWeight: 500,
    color: '#000',
    borderTop: '1px solid rgb(224, 224, 224)',
    fontSize: '14px',
  },
  tableFooter: {
    position: 'absolute',
    bottom: '55px',
  },
  backdrop: {
    position: 'absolute',
    zIndex: 3,
    color: '#282E67',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  tableText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
  },
})

const useStylesTooltip = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.primary.dark,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.dark,
    color: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    padding: 10,
    textAlign: 'center',
    marginTop: '10px',
  },
}))

type Props = {
  pageCount: number
  currentPage: number
  handlePageChange: (page: number) => void
  data: Data[]
  isLoading: boolean
  handleSort: (val: string) => void
  sortBy: string
  sortDirection: 'asc' | 'desc'
  handleSearch: (val: string) => void
  handleAction: (id: string) => void
}

export function DataTable({
  data,
  isLoading,
  handleSearch,
  handleSort,
  sortBy,
  sortDirection,
  pageCount,
  currentPage,
  handlePageChange,
  handleAction,
}: Props) {
  const classes = useStyles()
  const tooltipClasses = useStylesTooltip()
  const [searchValue, setSearchValue] = React.useState('')
  const inputRef = React.useRef<HTMLInputElement>()

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
    handleSearch(event.target.value)
  }

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    handlePageChange(value)
  }

  React.useEffect(() => {
    if (isLoading && searchValue) {
      setSearchValue('')
      if (inputRef.current !== undefined) {
        inputRef.current.value = ''
      }
    }
  }, [isLoading, searchValue])

  return (
    <>
      <Grid className={classes.root} container>
        <Grid item xs={12}>
          <Paper
            className={classes.paperContainer}
            elevation={0}
            variant="outlined"
          >
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={3}>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  type="search"
                  id="go-error-search-field"
                  margin="dense"
                  onChange={handleSearchChange}
                  inputRef={inputRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <TableContainer className={classes.table}>
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <TableSortLabel
                        active={sortBy === 'channel.channelName'}
                        direction={
                          sortBy === 'channel.channelName'
                            ? sortDirection
                            : 'asc'
                        }
                        onClick={() => handleSort('channel.channelName')}
                      >
                        Channel
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel
                        active={
                          sortBy === 'webhookConversion.webhookConversionUrl'
                        }
                        direction={
                          sortBy === 'webhookConversion.webhookConversionUrl'
                            ? sortDirection
                            : 'asc'
                        }
                        onClick={() =>
                          handleSort('webhookConversion.webhookConversionUrl')
                        }
                      >
                        URL
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel
                        active={
                          sortBy === 'webhookConversion.webhookConversionMethod'
                        }
                        direction={
                          sortBy === 'webhookConversion.webhookConversionMethod'
                            ? sortDirection
                            : 'asc'
                        }
                        onClick={() =>
                          handleSort(
                            'webhookConversion.webhookConversionMethod'
                          )
                        }
                      >
                        Method
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel
                        active={
                          sortBy === 'webhookConversion.webhookConversionStatus'
                        }
                        direction={
                          sortBy === 'webhookConversion.webhookConversionStatus'
                            ? sortDirection
                            : 'asc'
                        }
                        onClick={() =>
                          handleSort(
                            'webhookConversion.webhookConversionStatus'
                          )
                        }
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length ? (
                    data.map((row, idx) => (
                      <TableRow
                        key={`${row.webhookConversion.webhookConversionUuid}`}
                        hover
                        style={
                          idx % 2
                            ? { background: 'rgba(0, 0, 150, 0.025)' }
                            : { background: 'white' }
                        }
                      >
                        <TableCell
                          align="left"
                          className={classes.tableCell}
                          width={300}
                        >
                          {row.channel.channelName}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          <Tooltip
                            title={row.webhookConversion.webhookConversionUrl}
                            classes={tooltipClasses}
                            arrow
                          >
                            <div
                              className={classes.tableText}
                              style={{ width: '285px' }}
                            >
                              {row.webhookConversion.webhookConversionUrl}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row.webhookConversion.webhookConversionMethod}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row.webhookConversion.webhookConversionStatus ? (
                            <Icon style={{ color: green[400] }}>
                              check_circle
                            </Icon>
                          ) : (
                            <Icon style={{ color: red[400] }}>
                              remove_circle
                            </Icon>
                          )}
                        </TableCell>
                        <TableCell align="right" className={classes.tableCell}>
                          <IconButton
                            color="primary"
                            aria-label="edit webhook"
                            component="span"
                            onClick={() =>
                              handleAction(
                                row.webhookConversion.webhookConversionUuid
                              )
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        style={{ border: 'none', padding: '30px 0' }}
                        colSpan={7}
                        align="center"
                      >
                        No Webhooks found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                width: '100%',
                marginTop: '10px',
                display: pageCount ** pageCount > 1 ? 'flex' : 'none',
                justifyContent: 'center',
              }}
            >
              <Pagination
                count={pageCount}
                variant="outlined"
                shape="rounded"
                color="primary"
                page={currentPage}
                onChange={handlePagination}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
