import React from 'react'
import {
  Dashboard,
  ShowChart,
  Storefront,
  Web,
  Payment,
  Build,
} from '@material-ui/icons'

export const links = [
  {
    id: 'dashboard',
    text: 'Dashboard',
    subNav: [],
    url: '/',
    icon: <Dashboard />,
  },
  {
    id: 'insights',
    text: 'Insights',
    subNav: [],
    url: '/insights',
    icon: <ShowChart />,
  },
  {
    id: 'advertisers',
    text: 'Advertisers',
    subNav: [],
    url: '/advertisers',
    icon: <Storefront />,
  },
  {
    id: 'channels',
    text: 'Channels',
    subNav: [],
    url: '/channels',
    icon: <Web />,
  },
  {
    id: 'finance',
    text: 'Finance',
    subNav: [
      {
        id: 'finance/status-report',
        text: 'Status report',
        url: '/finance/status-report',
      },
      {
        id: 'finance/payment-notes',
        text: 'Payment notes',
        url: '/finance/payment-notes',
      },
    ],
    url: '',
    icon: <Payment />,
  },
  {
    id: 'tool',
    text: 'Tools',
    subNav: [
      {
        id: 'tools/url-shortener',
        text: 'URL shortener',
        url: '/tools/url-shortener',
      },
      {
        id: 'tools/webhooks',
        text: 'Webhooks',
        url: '/tools/webhooks',
      },
    ],
    url: '',
    icon: <Build />,
  },
]
