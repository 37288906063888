import React from 'react'
import { Grid } from '@material-ui/core'
import { DataType } from '../types'
import Card from './Card'

type Props = {
  isLoading: boolean
  data: DataType | null
}

export const formatter = new Intl.NumberFormat('da-DK')

export default function CardsContainer({ isLoading, data }: Props) {
  const currencyCode = window.localStorage.getItem('publisherCurrency') || 'DKK'
  return (
    <Grid style={{ marginTop: 10 }} container alignItems="center" spacing={3}>
      <Card
        title="Pending"
        value={`${formatter.format(
          data?.totals.sums.totalPendingNoPaymentReceived ?? 0
        )} ${currencyCode}`}
        isLoading={isLoading}
      />
      <Card
        title="Ready for payout"
        value={`${formatter.format(
          data?.totals.sums.totalPendingPaymentReceived ?? 0
        )} ${currencyCode}`}
        isLoading={isLoading}
      />
      <Card
        title="Paid out"
        value={`${formatter.format(
          data?.totals.sums.totalPaid ?? 0
        )} ${currencyCode}`}
        isLoading={isLoading}
      />
      <Card
        title="Rejected"
        value={`${formatter.format(
          data?.totals.sums.totalRejected ?? 0
        )} ${currencyCode}`}
        isLoading={isLoading}
      />
    </Grid>
  )
}
