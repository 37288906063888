import React, { useState, useEffect } from 'react'
import { Grid, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from './style'
import Loader from '../../components/Loader/'
import { makeCall } from '../../utils/api'
import { initialData } from './initialData'
import AdvertisersContainer from './AdvertisersContainer'
import Filters from './Filters'
import Header from './Header'
import { CountriesType, CountryType, StatusType } from './types'
import { orderBy } from 'lodash'

const useStyles = makeStyles({
  root: {
    marginTop: '22px',
    position: 'relative',
  },
  paperContainer: {
    padding: '16px',
    position: 'relative',
  },
  backdrop: {
    position: 'absolute',
    zIndex: 3,
    color: '#282E67',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
})

export default function AdvertiserPage() {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [masterData, setMasterData] = useState(initialData)
  const [advertisers, setAdvertisers] = useState(initialData.advertisers)
  const [countries, setCountries] = useState<CountriesType[]>([])
  const [pageCount, setPageCount] = useState(0)
  const [sortBy, setSortBy] = useState('')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState<StatusType>(null)
  const [country, setCountry] = useState<CountryType>(null)
  const [sortDirection, setSortDirection] = useState<'desc' | 'asc'>('asc')
  const PER_PAGE = 10

  useEffect(() => {
    const getData = async () => {
      const { data: profile } = await makeCall('GET', 'profiles/profile')
      const url = `advertisers`
      const { data } = await makeCall('GET', url)
      setMasterData(data)
      const results = data.advertisers.filter(
        (row: any) => row.advertiserCountryName === profile.user.userCountryName
      )
      setAdvertisers(results)
      setPageCount(Math.ceil(results.length / PER_PAGE))
      setIsLoading(false)
      setCountry({
        countryCode: profile.user.userCountryCode,
        countryName: profile.user.userCountryName,
        countryStatus: 1,
      })
    }
    getData()
  }, [])

  useEffect(() => {
    const getData = async () => {
      const url = `countries?countryStatus=true`
      const { data } = await makeCall('GET', url)
      setCountries(data)
    }
    getData()
  }, [])

  const handleSort = (newSort: string) => {
    let newSortDirection: 'asc' | 'desc' = 'asc'
    if (sortBy === newSort) {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }
    const sortedData = orderBy(advertisers, newSort, newSortDirection)
    setAdvertisers(sortedData)
    setSortBy(newSort)
    setSortDirection(newSortDirection)
  }

  const filterSearch = (val: string) => {
    let results = [...masterData.advertisers]
    if (val) {
      results = results.filter((row) =>
        row.advertiserName.toLowerCase().includes(val.toLowerCase())
      )
      if (status?.value) {
        results = results.filter(
          (row) => row.advertiserStatusCode === status?.value
        )
      }
      if (country?.countryName) {
        results = results.filter(
          (row) => row.advertiserCountryName === country?.countryName
        )
      }
    } else {
      if (status?.value) {
        results = results.filter(
          (row) => row.advertiserStatusCode === status?.value
        )
      }
      if (country?.countryName) {
        results = results.filter(
          (row) => row.advertiserCountryName === country?.countryName
        )
      }
    }
    setSearch(val)
    setAdvertisers(results)
    setPageCount(Math.ceil(results.length / PER_PAGE))
  }

  const filterStatus = (val: StatusType) => {
    let results = [...masterData.advertisers]
    if (val) {
      results = results.filter((row) => row.advertiserStatusCode === val?.value)
      if (search) {
        results = results.filter((row) =>
          row.advertiserName.toLowerCase().includes(search.toLowerCase())
        )
      }
      if (country?.countryName) {
        results = results.filter(
          (row) => row.advertiserCountryName === country?.countryName
        )
      }
    } else {
      if (search) {
        results = results.filter((row) =>
          row.advertiserName.toLowerCase().includes(search.toLowerCase())
        )
      }
      if (country?.countryName) {
        results = results.filter(
          (row) => row.advertiserCountryName === country?.countryName
        )
      }
    }
    setStatus(val)
    setAdvertisers(results)
    setPageCount(Math.ceil(results.length / PER_PAGE))
  }

  const filterCountry = (val: CountryType) => {
    let results = [...masterData.advertisers]
    if (val) {
      results = results.filter(
        (row) => row.advertiserCountryName === val?.countryName
      )
      if (status?.value) {
        results = results.filter(
          (row) => row.advertiserStatusCode === status?.value
        )
      }
      if (search) {
        results = results.filter((row) =>
          row.advertiserName.toLowerCase().includes(search.toLowerCase())
        )
      }
    } else {
      if (status?.value) {
        results = results.filter(
          (row) => row.advertiserStatusCode === status?.value
        )
      }
      if (search) {
        results = results.filter((row) =>
          row.advertiserName.toLowerCase().includes(search.toLowerCase())
        )
      }
    }
    setCountry(val)
    setAdvertisers(results)
    setPageCount(Math.ceil(results.length / PER_PAGE))
  }

  return (
    <>
      <Loader loading={isLoading} />
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <Header count={masterData.counts.all} />
        <Grid className={classes.root} container>
          <Grid item xs={12}>
            <Paper
              className={classes.paperContainer}
              elevation={0}
              variant="outlined"
            >
              <Filters
                countries={countries}
                search={search}
                status={status}
                country={country}
                setSearch={filterSearch}
                setCountry={filterCountry}
                setStatus={filterStatus}
              />
              <AdvertisersContainer
                data={advertisers}
                pageCount={pageCount}
                PER_PAGE={PER_PAGE}
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleSort={handleSort}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
