export const orange = '#ff6801'
export const inactive = '#9cb2d3'
export const white = '#fff'
export const blue = '#282e67'
export const AppBarColor = '#3c4d6d'
export const SideBarColor = '#293854'
export const NavHighlight = '#21cad2'

export const colors = {
  pageviews: {
    primary: '#ff8656',
    secondary: 'rgba(255,134,86,0.1)',
  },
  click: {
    primary: '#ffb000',
    secondary: 'rgba(255,176,0,0.1)',
  },
  conversion: {
    primary: '#6a98fd',
    secondary: 'rgba(106,152,253,0.1)',
  },
  commission: {
    primary: '#0ccfaf',
    secondary: 'rgba(79,220,197,0.1)',
  },
}
