import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import Loader from '../../../components/Loader'
import { makeCall } from '../../../utils/api'
import { Container } from './style'
import { Create } from './Create'
import { Advertiser, Channel, DataType } from './types'
import { DataTable } from './Table'
import { orderBy } from 'lodash'
import { Update } from './Update'

export default function URLShortener() {
  const [channels, setChannels] = React.useState<Channel[]>([])
  const [advertisers, setAdvertisers] = React.useState<Advertiser[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isCreate, setIsCreate] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isRefresh, setIsRefresh] = useState(true)
  const [data, setData] = useState<DataType[]>([])
  const [record, setRecord] = useState<DataType | null>(null)
  const [sortBy, setSortBy] = useState('urlShort.urlShortCode')
  const [sortDirection, setSortDirection] = useState<'desc' | 'asc'>('asc')
  const [searchVal, setSearchVal] = useState('')

  useEffect(() => {
    makeCall('GET', 'channels').then((res) => setChannels(res.data))
    makeCall('GET', 'advertisers').then((res) =>
      setAdvertisers(res.data.advertisers)
    )
  }, [])

  useEffect(() => {
    if (isRefresh) {
      setIsLoading(true)
      makeCall('GET', 'tools/urlshorts').then((res) => {
        setData(res.data)
        setIsLoading(false)
        setIsRefresh(false)
      })
    }
  }, [isRefresh])

  const getRecord = async (urlShortCode: string) => {
    setIsLoading(true)
    const response = await makeCall('GET', `tools/urlshorts/${urlShortCode}`)
    if (response.status === 200) {
      setRecord(response.data)
    }
    setIsLoading(false)
    setIsUpdate(true)
  }

  const getSearchedData = () => {
    if (searchVal && data.length) {
      let searchedData = data
      searchedData = searchedData.filter(
        (row) =>
          row.urlShort.urlShortCode
            .toString()
            .toLowerCase()
            .includes(searchVal) ||
          row.channel.channelUrl.toString().toLowerCase().includes(searchVal) ||
          row.advertiser.advertiserUrl
            .toString()
            .toLowerCase()
            .includes(searchVal)
      )
      return searchedData
    }
    return data
  }

  const handleSort = (newSort: string) => {
    if (data) {
      let newSortDirection: 'asc' | 'desc' = 'asc'
      if (sortBy === newSort) {
        newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
      }
      const sortedData = orderBy(data, newSort, newSortDirection)
      setSortBy(newSort)
      setSortDirection(newSortDirection)
      setData(sortedData)
    }
  }

  return (
    <>
      <Loader loading={isLoading} />
      <Create
        isOpen={isCreate}
        handleClose={() => setIsCreate(false)}
        refresh={() => setIsRefresh(true)}
        channels={channels}
        advertisers={advertisers}
      />
      <Update
        isOpen={isUpdate}
        handleClose={() => setIsUpdate(false)}
        refresh={() => setIsRefresh(true)}
        record={record}
      />
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <Grid container spacing={0} justify="space-between" alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h5" data-testid="page-heading">
              URL Shortener
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setIsCreate(true)}
            >
              Create New
            </Button>
          </Grid>
        </Grid>
        <DataTable
          data={getSearchedData()}
          pageCount={0}
          currentPage={0}
          handlePageChange={() => null}
          isLoading={isLoading}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          handleSearch={setSearchVal}
          handleAction={getRecord}
        />
      </Container>
    </>
  )
}
