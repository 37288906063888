import { InsightsDataType } from './types'
import { orderBy } from 'lodash'

export function formatInsightsData(data: any) {
  return data
}

export function sortData(
  data: InsightsDataType[],
  sortBy: string,
  direction: 'asc' | 'desc'
) {
  const sortedData = orderBy(data, sortBy, direction)

  return sortedData
}

export function getDateOfWeek(w: any, y: any) {
  var d = 1 + (w - 0) * 7 // 1st of January + 7 days for each week

  return new Date(y, 0, d)
}
