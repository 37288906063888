import React, { useState, useEffect } from 'react'
import { makeCall } from '../../utils/api'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import format from 'date-fns/format'
import { Grid, Typography } from '@material-ui/core'
import useInterval from '../../hooks/useInterval'
import CardsContainer from './Components/Card'
import ChartContainer from './Components/Chart/'
import ChannelsContainer from './Components/Channels'
import ConversionsContainer from './Components/Conversions/'
import ClicksContainer from './Components/Clicks/'
import Datepicker from './Components/Datepicker/'
import Loader from '../../components/Loader/'
import { Container } from '../../elements/container'
import {
  formatDashboardData,
  formatClicksData,
  formatConversionsData,
} from './utils'
import { DataType, ConversionDataType, ClickDataType } from './types'
import {
  initialData,
  initialConversionsData,
  initialClicksData,
} from './initialData'

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<DataType>(initialData)
  const [clicksData, setClicksData] = useState<ClickDataType[]>(
    initialClicksData
  )
  const [conversionsData, setConversionsData] = useState<ConversionDataType[]>(
    initialConversionsData
  )
  const [pollCount, setPollCount] = useState(0)

  const [dateObj, setDateObj] = useState({
    fromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  })

  useEffect(() => {
    const getData = async () => {
      const url = `dashboards/stats?fromDate=${dateObj.fromDate}&toDate=${dateObj.toDate}`
      const { data } = await makeCall('GET', url)
      const formattedData = formatDashboardData(data)
      setData(formattedData)
      setIsLoading(false)
    }
    getData()
  }, [dateObj])

  useEffect(() => {
    const getData = async () => {
      const { data: clicksData } = await makeCall(
        'GET',
        `dashboards/latest/clicks`
      )
      const { data: conversionsData } = await makeCall(
        'GET',
        `dashboards/latest/conversions`
      )
      const formattedClicksData = formatClicksData(clicksData)
      const formattedConversionsData = formatConversionsData(conversionsData)

      setClicksData(formattedClicksData)
      setConversionsData(formattedConversionsData)
    }
    getData()
  }, [pollCount])

  useInterval(() => {
    setPollCount(pollCount + 1)
  }, 60000)

  const setDate = (start: Date, end: Date) => {
    setDateObj({
      fromDate: format(start, 'yyyy-MM-dd'),
      toDate: format(end, 'yyyy-MM-dd'),
    })
    setIsLoading(true)
  }

  return (
    <>
      <Loader loading={isLoading} />
      <Container>
        <Grid container spacing={0} justify="space-between" alignItems="center">
          <Grid item xs={9}>
            <Typography variant="h5">Dashboard</Typography>
            <Typography gutterBottom color="textSecondary">
              Get a brief overview of your performance
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Datepicker
              change={setDate}
              startDate={startOfMonth(new Date())}
              endDate={endOfMonth(new Date())}
            />
          </Grid>
        </Grid>

        <CardsContainer data={data.cardsData} />

        <ChartContainer data={data.chartData} />

        {data.channelsData.length ? (
          <ChannelsContainer data={data.channelsData} />
        ) : null}

        <Grid
          style={{ marginTop: '22px' }}
          container
          spacing={3}
          justify="space-between"
          alignItems="center"
        >
          <ConversionsContainer data={conversionsData} />
          <ClicksContainer data={clicksData} />
        </Grid>
      </Container>
    </>
  )
}
