import React, { useState } from 'react'
import {
  LineChart,
  Line,
  Tooltip,
  YAxis,
  XAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import { Card, CardContent, Typography, Divider } from '@material-ui/core'
import { colors } from '../../colors'
import { ChartDataType, CurrencyType } from '../../types'

import styled from 'styled-components'

const Container = styled.div`
  padding-top: 24px;
  padding-bottom: 16px;
  .recharts-cartesian-axis-tick {
    font-size: 14px;
    font-family: Rubik, sans-serif;
  }
`

type Props = {
  filters: string[]
  data: ChartDataType[]
}

const fillOpacity = 0
const strokeOpacity = 0.6
const strokeOpacityActive = 0.9
const strokeWidth = 4

export default function Lines({ filters, data }: Props) {
  const [activeColor, setActiveColor] = useState('')
  const [activeArea, setActiveArea] = useState('')
  const [cx, setCX] = useState(0)
  const [cy, setCY] = useState(0)

  const handleDotEnter = (e: any) => {
    setActiveArea(e.dataKey)
    setActiveColor(e.fill)
    setCX(e.cx)
    setCY(e.cy)
  }
  const handleDotLeave = () => {
    setActiveArea('')
    setCX(0)
    setCY(0)
  }
  const handleAreaEnter = (e: string) => {
    setActiveArea(e)
  }
  const handleAreaLeave = () => {
    setActiveArea('')
  }

  const renderTargetLine = () => {
    if (cy && cx) {
      return (
        <svg>
          <line
            id="HorizontalLine"
            y1={cy}
            y2={cy}
            x1={0}
            x2={cx}
            stroke={activeColor}
            strokeWidth={2}
          />
          <line
            id="VerticalLine"
            y1={320}
            y2={cy}
            x1={cx}
            x2={cx}
            stroke={activeColor}
            strokeWidth={2}
          />
        </svg>
      )
    }
  }

  const renderTooltip = (props: any) => {
    if (typeof props.payload != 'undefined' && props.payload != null) {
      if (typeof props.payload[0] != 'undefined') {
        const formatter = new Intl.NumberFormat('da-DK')
        return (
          <Card style={{ zIndex: 1000 }}>
            <CardContent>
              <Typography variant="subtitle2">
                <b>{props.payload[0].payload.date}</b>
              </Typography>
              <Divider />
              <Typography style={{ color: colors.pageviews.primary }}>
                Pageviews:{' '}
                {formatter.format(props.payload[0].payload.pageviews)}
              </Typography>
              <Typography style={{ color: colors.click.primary }}>
                Clicks: {formatter.format(props.payload[0].payload.clicks)}
              </Typography>
              <Typography style={{ color: colors.conversion.primary }}>
                Conversions:{' '}
                {formatter.format(props.payload[0].payload.conversions)}
              </Typography>
              <Divider />
              {props.payload[0].payload.currencies.length > 1 ? (
                <div>
                  {props.payload[0].payload.currencies.map(
                    (currency: CurrencyType) => {
                      return (
                        <Typography key={`currency-${currency.currencyCode}`}>
                          {currency.currencyCode} :{' '}
                          {formatter.format(currency.value)}
                        </Typography>
                      )
                    }
                  )}
                  <Divider />
                </div>
              ) : null}
              <Typography style={{ color: colors.commission.primary }}>
                Commission:{' '}
                {window.localStorage.getItem('publisherCurrency') || 'DKK'}{' '}
                {formatter.format(props.payload[0].payload.commission)}
              </Typography>
            </CardContent>
          </Card>
        )
      }
    }
  }
  return (
    <Container>
      <ResponsiveContainer width="100%" height={350}>
        <LineChart
          data={data}
          margin={{
            top: 7,
            right: 7,
            left: 7,
            bottom: 7,
          }}
        >
          <Tooltip
            content={(props: any) => renderTooltip(props)}
            wrapperStyle={{ zIndex: 10000 }}
          />
          <YAxis yAxisId="pageviews" hide allowDataOverflow={true} />
          <YAxis yAxisId="commission" hide allowDataOverflow={true} />
          <YAxis yAxisId="clicks" hide allowDataOverflow={true} />
          <YAxis yAxisId="conversions" hide allowDataOverflow={true} />
          <XAxis
            dataKey="date"
            allowDataOverflow={true}
            interval="preserveStartEnd"
            axisLine={false}
            tickLine={false}
            scale="point"
            tickMargin={10}
            minTickGap={55}
          />
          <CartesianGrid vertical={false} strokeDasharray="5 3" />
          {renderTargetLine()}
          {filters.includes('pageviews') ? null : (
            <Line
              type="monotone"
              yAxisId="pageviews"
              dataKey={'pageviews'}
              stroke={colors.pageviews.primary}
              strokeOpacity={
                activeArea === 'pageviews' ? strokeOpacityActive : strokeOpacity
              }
              fillOpacity={fillOpacity}
              fill={colors.pageviews.primary}
              activeDot={{
                stroke: '#fff',
                strokeWidth: 3,
                r: 7,
                fill: colors.pageviews.primary,
                onMouseEnter: (e: any) => handleDotEnter(e),
                onMouseLeave: () => handleDotLeave(),
              }}
              strokeWidth={strokeWidth}
              dot={false}
              onMouseEnter={() => handleAreaEnter('pageviews')}
              onMouseLeave={() => handleAreaLeave()}
            />
          )}
          {filters.includes('clicks') ? null : (
            <Line
              type="monotone"
              yAxisId="clicks"
              dataKey={'clicks'}
              stroke={colors.click.primary}
              strokeOpacity={
                activeArea === 'clicks' ? strokeOpacityActive : strokeOpacity
              }
              fillOpacity={fillOpacity}
              fill={colors.click.primary}
              activeDot={{
                stroke: '#fff',
                strokeWidth: 3,
                r: 7,
                fill: colors.click.primary,
                onMouseEnter: (e: any) => handleDotEnter(e),
                onMouseLeave: () => handleDotLeave(),
              }}
              strokeWidth={strokeWidth}
              dot={false}
              onMouseEnter={() => handleAreaEnter('clicks')}
              onMouseLeave={() => handleAreaLeave()}
            />
          )}
          {filters.includes('conversions') ? null : (
            <Line
              type="monotone"
              yAxisId="conversions"
              dataKey={'conversions'}
              stroke={colors.conversion.primary}
              strokeOpacity={
                activeArea === 'conversions'
                  ? strokeOpacityActive
                  : strokeOpacity
              }
              fillOpacity={fillOpacity}
              fill={colors.conversion.primary}
              activeDot={{
                stroke: '#fff',
                strokeWidth: 3,
                r: 7,
                fill: colors.conversion.primary,
                onMouseEnter: (e: any) => handleDotEnter(e),
                onMouseLeave: () => handleDotLeave(),
              }}
              strokeWidth={strokeWidth}
              dot={false}
              onMouseEnter={() => handleAreaEnter('conversions')}
              onMouseLeave={() => handleAreaLeave()}
            />
          )}
          {filters.includes('commission') ? null : (
            <Line
              type="monotone"
              yAxisId="commission"
              dataKey={'commission'}
              stroke={colors.commission.primary}
              strokeOpacity={
                activeArea === 'commission'
                  ? strokeOpacityActive
                  : strokeOpacity
              }
              fillOpacity={fillOpacity}
              fill={colors.commission.primary}
              activeDot={{
                stroke: '#fff',
                strokeWidth: 3,
                r: 7,
                fill: colors.commission.primary,
                onMouseEnter: (e: any) => handleDotEnter(e),
                onMouseLeave: () => handleDotLeave(),
              }}
              strokeWidth={strokeWidth}
              dot={false}
              onMouseEnter={() => handleAreaEnter('commission')}
              onMouseLeave={() => handleAreaLeave()}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </Container>
  )
}
