import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import { SideBarColor, NavHighlight } from '../../elements/colors'

const useStyles = makeStyles({
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 5,
    zIndex: 99999999,
  },
  colorPrimary: {
    backgroundColor: SideBarColor,
  },
  bar: {
    backgroundColor: NavHighlight,
  },
})

export default function Loader({ loading }) {
  const classes = useStyles()
  if (loading) {
    return (
      <LinearProgress
        classes={{
          root: classes.loading,
          colorPrimary: classes.colorPrimary,
          bar: classes.bar,
        }}
      />
    )
  }

  return <div />
}
