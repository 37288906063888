import React, { ReactElement } from 'react'
import {
  IconButton,
  Tooltip,
  GridSize,
  makeStyles,
  Theme,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'

type Props = {
  text: string | ReactElement
  col?: boolean | GridSize | undefined
}

const useStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.primary.dark,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.dark,
    color: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(16),
    border: 'none',
    padding: 10,
    textAlign: 'left',
    marginTop: '10px',
  },
}))

export default function CustomTooltip({ text, col = 2 }: Props) {
  const classes = useStyles()
  return (
    <Tooltip
      placement="top"
      classes={classes}
      arrow
      title={text}
      aria-label="webhook help"
    >
      <IconButton aria-label="webhook help" color="primary">
        <HelpIcon />
      </IconButton>
    </Tooltip>
  )
}
