import React from 'react'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Pagination from '@material-ui/lab/Pagination'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { DataType } from '../types'
import TextTruncate from 'react-text-truncate'
import StatusBar from './StatusBar'
import IconButton from '@material-ui/core/IconButton'
import DownloadIcon from '@material-ui/icons/CloudDownload'

const useStyles = makeStyles({
  table: {
    maxHeight: '600px',
  },
  root: {
    marginTop: '20px',
    position: 'relative',
  },
  paperContainer: {
    padding: '16px',
    position: 'relative',
  },
  pill: {
    padding: '5px',
    fontWeight: 500,
    borderRadius: '5px',
  },
  tableCell: {
    borderBottom: 'none',
  },
  tableFooterCell: {
    borderBottom: 'none',
    fontWeight: 500,
    color: '#000',
    borderTop: '1px solid rgb(224, 224, 224)',
    fontSize: '14px',
  },
  tableFooter: {
    position: 'absolute',
    bottom: '55px',
  },
  backdrop: {
    position: 'absolute',
    zIndex: 3,
    color: '#282E67',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
})

type Props = {
  pageCount: number
  currentPage: number
  handlePageChange: (page: number) => void
  data: DataType | null
  isLoading: boolean
  handleSort: (val: string) => void
  sortBy: string
  sortDirection: 'asc' | 'desc'
  handleSearch: (val: string) => void
  handleCSVDownloadClick: () => void
}

export default function DataTable({
  pageCount,
  currentPage,
  handlePageChange,
  data,
  isLoading,
  handleSort,
  sortBy,
  sortDirection,
  handleSearch,
  handleCSVDownloadClick,
}: Props) {
  const classes = useStyles()
  const [searchValue, setSearchValue] = React.useState('')
  const inputRef = React.useRef<HTMLInputElement>()

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
    handleSearch(event.target.value)
  }

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    handlePageChange(value)
  }

  const formatter = new Intl.NumberFormat('da-DK')

  React.useEffect(() => {
    if (isLoading && searchValue) {
      setSearchValue('')
      if (inputRef.current !== undefined) {
        inputRef.current.value = ''
      }
    }
  }, [isLoading, searchValue])

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <Paper
          className={classes.paperContainer}
          elevation={0}
          variant="outlined"
        >
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            style={{ marginBottom: 10 }}
          >
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                type="search"
                id="go-error-search-field"
                margin="dense"
                onChange={handleSearchChange}
                inputRef={inputRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                aria-label="download"
                title="Download CSV"
                color="primary"
                onClick={handleCSVDownloadClick}
              >
                <DownloadIcon />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer className={classes.table}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortBy === 'timeGrp'}
                      direction={sortBy === 'timeGrp' ? sortDirection : 'asc'}
                      onClick={() => handleSort('timeGrp')}
                    >
                      Time group
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'pendingNoPaymentReceived'}
                      direction={
                        sortBy === 'pendingNoPaymentReceived'
                          ? sortDirection
                          : 'asc'
                      }
                      onClick={() => handleSort('pendingNoPaymentReceived')}
                    >
                      Pending
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'pendingPaymentReceived'}
                      direction={
                        sortBy === 'pendingPaymentReceived'
                          ? sortDirection
                          : 'asc'
                      }
                      onClick={() => handleSort('pendingPaymentReceived')}
                    >
                      Ready for payout
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'paid'}
                      direction={sortBy === 'paid' ? sortDirection : 'asc'}
                      onClick={() => handleSort('paid')}
                    >
                      Paid out
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'rejected'}
                      direction={sortBy === 'rejected' ? sortDirection : 'asc'}
                      onClick={() => handleSort('rejected')}
                    >
                      Rejected
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'currencyCode'}
                      direction={
                        sortBy === 'currencyCode' ? sortDirection : 'asc'
                      }
                      onClick={() => handleSort('currencyCode')}
                    >
                      Currency
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'percent'}
                      direction={sortBy === 'percent' ? sortDirection : 'asc'}
                      onClick={() => handleSort('percent')}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.rows.length ? (
                  data.rows.map((row, idx) => (
                    <TableRow
                      key={`${row.currencyCode}-${row.timeGrp}-${row.grp}`}
                      hover
                      style={
                        idx % 2
                          ? { background: 'rgba(0, 0, 150, 0.025)' }
                          : { background: 'white' }
                      }
                    >
                      <TableCell className={classes.tableCell}>
                        <div style={{ width: '100px' }}>
                          <TextTruncate line={1} text={row.timeGrp} />
                        </div>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <span>
                          {formatter.format(row.pendingNoPaymentReceived)}
                        </span>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <span>
                          {formatter.format(row.pendingPaymentReceived)}
                        </span>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <span>
                          <span>{formatter.format(row.paid)}</span>
                        </span>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <span>
                          <span>{formatter.format(row.rejected)}</span>
                        </span>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <span>
                          {' '}
                          <span>{row.currencyCode}</span>
                        </span>
                      </TableCell>
                      <TableCell
                        // align="right"
                        className={classes.tableCell}
                        width={170}
                      >
                        <StatusBar
                          data={row.percent}
                          isLast={data.rows.length === idx + 1}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      style={{ border: 'none', padding: '30px 0' }}
                      colSpan={7}
                      align="center"
                    >
                      No data found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              width: '100%',
              marginTop: '10px',
              display: pageCount ** pageCount > 1 ? 'flex' : 'none',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={pageCount}
              variant="outlined"
              shape="rounded"
              color="primary"
              page={currentPage}
              onChange={handlePagination}
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}
