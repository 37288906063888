import React from 'react'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../colors'
import { ChannelDataType } from '../../types'

const useStyles = makeStyles({
  table: {
    // maxHeight: '300px',
  },
  root: {
    marginTop: '22px',
  },
  paperContainer: {
    padding: '16px',
  },
  pill: {
    padding: '5px',
    fontWeight: 500,
    borderRadius: '5px',
  },
  pageviews: {
    color: colors.pageviews.primary,
    backgroundColor: colors.pageviews.secondary,
  },
  clicks: {
    color: colors.click.primary,
    backgroundColor: colors.click.secondary,
  },
  conversions: {
    color: colors.conversion.primary,
    backgroundColor: colors.conversion.secondary,
  },
  commission: {
    color: colors.commission.primary,
    backgroundColor: colors.commission.secondary,
  },
  tableCell: {
    borderBottom: 'none',
  },
})

type Props = {
  data: ChannelDataType[]
}

export default function ChannelsContainer({ data }: Props) {
  const classes = useStyles()

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <Paper
          className={classes.paperContainer}
          elevation={0}
          variant="outlined"
        >
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Channel</TableCell>
                  <TableCell align="right">Pageviews</TableCell>
                  <TableCell align="right">Clicks</TableCell>
                  <TableCell align="right">Conversions</TableCell>
                  <TableCell align="right">Commission</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, idx) => (
                  <TableRow
                    key={row.channelUuid}
                    hover
                    style={
                      idx % 2
                        ? { background: 'rgba(0, 0, 150, 0.025)' }
                        : { background: 'white' }
                    }
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.channelUrl}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <span
                        className={[classes.pageviews, classes.pill].join(' ')}
                      >
                        {row.pageviews}
                      </span>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <span
                        className={[classes.clicks, classes.pill].join(' ')}
                      >
                        {row.clicks}
                      </span>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <span
                        className={[classes.conversions, classes.pill].join(
                          ' '
                        )}
                      >
                        {row.conversions}
                      </span>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <span
                        className={[classes.commission, classes.pill].join(' ')}
                      >
                        {row.commission}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  )
}
