import React, { useState, useEffect } from 'react'
import { Container } from './style'
import Loader from '../../components/Loader/'
import { makeCall, generateFile } from '../../utils/api'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import format from 'date-fns/format'
import { Grid, Typography } from '@material-ui/core'
import Datepicker from '../Dashboard/Components/Datepicker'
import TabsContainer from './Components/Tabs'
import Table from './Components/Table'
import { formatInsightsData, sortData, getDateOfWeek } from './utils'
import { initialData } from './initialData'
import { InsightsDataType, GraphDataType, GroupFiltersType } from './types'
import ChartContainer from './Components/Chart'
import FiltersContainer from './Components/Filters'

export default function Insights() {
  const [data, setData] = useState(initialData)
  const [dataToRender, setDataToRender] = useState<InsightsDataType[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [sortBy, setSortBy] = useState('grpName')
  const [sortDirection, setSortDirection] = useState<'desc' | 'asc'>('asc')
  const [graphData, setGraphData] = useState<GraphDataType>({
    lines: [],
    bars: [],
  })

  const [isLoading, setIsLoading] = useState(true)
  const [group, setGroup] = useState('date')
  const [groupFilters, setGroupFilters] = useState<GroupFiltersType>({
    date: '',
    week: '',
    month: '',
    year: '',
    advertiserUuid: '',
    advertiserLabel: '',
    channelLabel: '',
    channelUuid: '',
    device: '',
    pageUrl: '',
    referer: '',
  })

  const PER_PAGE = 30

  const [dateObj, setDateObj] = useState({
    fromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  })

  const [searchValue, setSearchValue] = React.useState('')

  const [url, setUrl] = useState('')

  useEffect(() => {
    const getData = async () => {
      let url = `insights?fromDate=${dateObj.fromDate}&toDate=${dateObj.toDate}&groupBy=${group}`

      if (groupFilters.year) {
        const startDate = format(
          new Date(parseInt(groupFilters.year), 0, 1),
          'yyy-MM-dd'
        )
        const endDate = format(
          new Date(parseInt(groupFilters.year), 11, 31),
          'yyy-MM-dd'
        )
        url = `insights?fromDate=${startDate}&toDate=${endDate}&groupBy=${group}`
      }
      if (groupFilters.month) {
        const monthArr = groupFilters.month.split('-')
        const startDate = format(
          new Date(parseInt(monthArr[0]), parseInt(monthArr[1]) - 1, 1),
          'yyy-MM-dd'
        )
        const endDate = format(
          endOfMonth(
            new Date(parseInt(monthArr[0]), parseInt(monthArr[1]) - 1, 1)
          ),
          'yyy-MM-dd'
        )
        console.log(startDate, endDate)
        url = `insights?fromDate=${startDate}&toDate=${endDate}&groupBy=${group}`
      }
      if (groupFilters.week) {
        const weekArr = groupFilters.week.split('-')
        const weekDate = getDateOfWeek(
          parseInt(weekArr[1]),
          parseInt(weekArr[0])
        )
        const startDate = format(startOfWeek(weekDate), 'yyy-MM-dd')
        const endDate = format(endOfWeek(weekDate), 'yyy-MM-dd')
        console.log(startDate, endDate)
        url = `insights?fromDate=${startDate}&toDate=${endDate}&groupBy=${group}`
      }
      if (groupFilters.date) {
        url = `insights?fromDate=${groupFilters.date}&toDate=${groupFilters.date}&groupBy=${group}`
      }

      if (groupFilters.advertiserUuid) {
        url = url + `&advertiserUuid=${groupFilters.advertiserUuid}`
      }
      if (groupFilters.channelUuid) {
        url = url + `&channelUuid=${groupFilters.channelUuid}`
      }
      if (groupFilters.device) {
        url = url + `&device=${groupFilters.device}`
      }
      if (groupFilters.pageUrl) {
        url = url + `&pageUrl=${groupFilters.pageUrl}`
      }
      if (groupFilters.referer) {
        url = url + `&referer=${groupFilters.referer}`
      }
      const { data } = await makeCall('GET', url)
      const formattedData = formatInsightsData(data)
      const dataLength = data.insights.length
      const currentPage = 1
      const pageCount = Math.ceil(dataLength / PER_PAGE)
      const dataToRender = data.insights.slice(0, PER_PAGE)

      setData(formattedData)
      setCurrentPage(currentPage)
      setPageCount(pageCount)
      setDataToRender(dataToRender)
      setGraphData(data.graphs)
      setUrl(url)
      if (group === 'date') {
        setSortBy('grpName')
        setSortDirection('asc')
      } else {
        setSortBy('commission')
        setSortDirection('desc')
      }
      setIsLoading(false)
      setSearchValue('')
    }
    getData()
  }, [dateObj, group, groupFilters])

  const setDate = (start: Date, end: Date) => {
    setDateObj({
      fromDate: format(start, 'yyyy-MM-dd'),
      toDate: format(end, 'yyyy-MM-dd'),
    })
    setIsLoading(true)
  }

  const handlePageChange = (page: number) => {
    if (page === currentPage) return false
    const startIndex = (page - 1) * PER_PAGE
    const dataToRender = data.insights.slice(startIndex, startIndex + PER_PAGE)
    setCurrentPage(page)
    setDataToRender(dataToRender)
  }

  const handleGroupChange = (newGroup: string) => {
    if (group !== newGroup) {
      setIsLoading(true)
      setGroup(newGroup)
    }
  }

  const handleSort = (newSort: string) => {
    let newSortDirection: 'asc' | 'desc' = 'asc'
    if (sortBy === newSort) {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }
    const sortedData = sortData(data.insights, newSort, newSortDirection)
    const filteredData = sortedData.filter((row) =>
      row.grpName.includes(searchValue)
    )
    setData({ ...data, insights: sortedData })
    setSortBy(newSort)
    setSortDirection(newSortDirection)
    setCurrentPage(1)
    setDataToRender(filteredData.slice(0, PER_PAGE))
  }

  const handleFilterClick = (grpFilter: string, grpName: string) => {
    const newFilters = { ...groupFilters }
    setIsLoading(true)
    switch (group) {
      case 'date':
        newFilters.date = grpFilter
        break
      case 'week':
        newFilters.week = grpFilter
        break
      case 'month':
        newFilters.month = grpFilter
        break
      case 'year':
        newFilters.year = grpFilter
        break
      case 'advertiser':
        newFilters.advertiserUuid = grpFilter
        newFilters.advertiserLabel = grpName
        break
      case 'channel':
        newFilters.channelUuid = grpFilter
        newFilters.channelLabel = grpName
        break
      case 'page':
        newFilters.pageUrl = grpFilter
        break
      case 'device':
        newFilters.device = grpFilter
        break
      case 'referer':
        newFilters.referer = grpFilter
        break
      default:
        break
    }

    setGroupFilters(newFilters)
  }

  const removeFilter = (filterId: string) => {
    const str = filterId as keyof typeof groupFilters
    setIsLoading(true)
    const newFilters = { ...groupFilters }
    newFilters[str] = ''

    if (filterId === 'advertiserUuid') {
      newFilters.advertiserLabel = ''
    }
    if (filterId === 'channelUuid') {
      newFilters.channelLabel = ''
      newFilters['pageUrl'] = ''
      if (group === 'page') {
        setGroup('channel');
      }
    }
    setGroupFilters(newFilters)
  }

  const handleSearch = (val: string) => {
    const searchedData = [...data.insights].filter((row) =>
      row.grpName.toLowerCase().includes(val.toLowerCase())
    )
    const dataLength = searchedData.length
    const currentPage = 1
    const pageCount = Math.ceil(dataLength / PER_PAGE)
    const dataToRender = searchedData.slice(0, PER_PAGE)
    setCurrentPage(currentPage)
    setPageCount(pageCount)
    setDataToRender(dataToRender)
    setSearchValue(val)
  }

  const openAsCSV = async () => {
    if (data && data.insights.length) {
      setIsLoading(true)
      const response = await makeCall('GET', url, {}, 'text/csv')
      if (response.data) {
        generateFile(response.data, `insights-report.csv`)
      }
      setIsLoading(false)
    }
  }

  return (
    <>
      <Loader loading={isLoading} />
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <Grid container spacing={0} justify="space-between" alignItems="center">
          <Grid item xs={9}>
            <Typography variant="h5" data-testid="page-heading">
              Insights
            </Typography>
            <Typography gutterBottom color="textSecondary">
              Dive into your performance metrics
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Datepicker
              change={setDate}
              startDate={startOfMonth(new Date())}
              endDate={endOfMonth(new Date())}
            />
          </Grid>
        </Grid>

        <Grid container>
          <TabsContainer handleGroupChange={handleGroupChange} group={group} groupFilters={groupFilters} />
        </Grid>
        <ChartContainer data={graphData} />
        <FiltersContainer
          data={groupFilters}
          handleFilterClick={removeFilter}
        />
        <Table
          sortBy={sortBy}
          sortDirection={sortDirection}
          handleSort={handleSort}
          isLoading={isLoading}
          currentPage={currentPage}
          pageCount={pageCount}
          handlePageChange={handlePageChange}
          data={dataToRender}
          totals={data.totals}
          handleFilterClick={handleFilterClick}
          handleSearch={handleSearch}
          handleCSVDownloadClick={openAsCSV}
        />
      </Container>
    </>
  )
}
