const currencyCode = window.localStorage.getItem('publisherCurrency') || 'DKK'
export const initialData = {
  cardsData: {
    pageviews: '0',
    clicks: '0',
    conversions: '0',
    commission: `${currencyCode} 0`,
    epm: `${currencyCode} 0`,
    ctr: `0 %`,
    cr: `0 %`,
    epc: `${currencyCode} 0`,
  },
  chartData: [],
  channelsData: [],
}

export const initialConversionsData = []

export const initialClicksData = []
