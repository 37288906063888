import { createGlobalStyle } from 'styled-components'
import ProCyr from '../assets/fonts/HarmoniaSansProCyr-Bold.otf'
const GlobalStyles = createGlobalStyle`
@font-face {
        font-family: 'ProCyr';
        src: local('ProCyr'),
        url(${ProCyr}) format('opentype'); 
        font-weight: bold;
        font-style: normal;
    }
   
  html{
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
  }
  body {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgba(156, 178, 211, 0.1); 
  }
  main {
    position: relative;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: 'ProCyr', sans-serif;
    font-weight: bold;
  }
`

export default GlobalStyles
