import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { makeStyles } from '@material-ui/core/styles'
import { AdvertiserType } from './types'
import AdvertiserRow from './AdvertiserRow'

type Props = {
  data: AdvertiserType[]
  PER_PAGE: number
  pageCount: number
  sortBy: string
  sortDirection: 'asc' | 'desc'
  handleSort: (val: string) => void
}

const useStyles = makeStyles({
  table: {
    marginTop: '22px',
    maxHeight: '680px',
  },

  backdrop: {
    position: 'absolute',
    zIndex: 3,
    color: '#282E67',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
})

export default function AdvertisersContainer({
  data,
  pageCount,
  PER_PAGE,
  handleSort,
  sortBy,
  sortDirection,
}: Props) {
  const [currentPage, setCurrentPage] = useState(1)
  const [startIndex, setStartIndex] = useState(0)

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (value === currentPage) return false
    const startIndex = (value - 1) * PER_PAGE
    setCurrentPage(value)
    setStartIndex(startIndex)
  }

  const classes = useStyles()

  return (
    <>
      <TableContainer className={classes.table}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'advertiserStatusCodeSortIndex'}
                  direction={
                    sortBy === 'advertiserStatusCodeSortIndex'
                      ? sortDirection
                      : 'asc'
                  }
                  onClick={() => handleSort('advertiserStatusCodeSortIndex')}
                >
                  <Typography variant="subtitle2" color="primary">
                    Status
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'advertiserName'}
                  direction={
                    sortBy === 'advertiserName' ? sortDirection : 'asc'
                  }
                  onClick={() => handleSort('advertiserName')}
                >
                  <Typography variant="subtitle2" color="primary">
                    Name
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'advertiserCountryName'}
                  direction={
                    sortBy === 'advertiserCountryName' ? sortDirection : 'asc'
                  }
                  onClick={() => handleSort('advertiserCountryName')}
                >
                  <Typography variant="subtitle2" color="primary">
                    Country
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'advertiserPayout.maxPayoutValue'}
                  direction={
                    sortBy === 'advertiserPayout.maxPayoutValue'
                      ? sortDirection
                      : 'asc'
                  }
                  onClick={() => handleSort('advertiserPayout.maxPayoutValue')}
                >
                  <Typography variant="subtitle2" color="primary">
                    Commission (up to)
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'advertiserIndex'}
                  direction={
                    sortBy === 'advertiserIndex' ? sortDirection : 'asc'
                  }
                  onClick={() => handleSort('advertiserIndex')}
                >
                  <Typography variant="subtitle2" color="primary">
                    Performance
                  </Typography>
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(startIndex, startIndex + PER_PAGE).map((row, idx) => (
              <AdvertiserRow row={row} idx={idx} key={row.advertiserUuid} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: '100%',
          marginTop: '30px',
          marginBottom: '10px',
          display: pageCount > 1 ? 'flex' : 'none',
          justifyContent: 'center',
        }}
      >
        <Pagination
          count={pageCount}
          variant="outlined"
          shape="rounded"
          color="primary"
          page={currentPage}
          onChange={handlePagination}
        />
      </div>
    </>
  )
}
