import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import { BaseModalType, BaseFormType } from './types'
import { createDefaultValues } from './initialValues'
import { makeCall } from '../../../utils/api'

export function Create({
  isOpen,
  handleClose,
  channels,
  advertisers,
  refresh,
}: BaseModalType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
    setValue,
  } = useForm<BaseFormType>({ defaultValues: createDefaultValues })

  const resetForm = () => {
    reset(createDefaultValues)
    handleClose()
  }

  const onSubmit = async (data: BaseFormType) => {
    const payload = {
      ...data,
      urlShortChannelUuid: data.urlShortChannelUuid?.channelUuid,
      urlShortAdvertiserUuid: data.urlShortAdvertiserUuid?.advertiserUuid,
    }
    await makeCall('POST', 'tools/urlshorts', payload)
    reset(createDefaultValues)
    handleClose()
    refresh()
  }

  return (
    <>
      <Dialog
        open={isOpen}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Create URL</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ marginBottom: 20 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="urlShortChannelUuid"
                  control={control}
                  rules={{ required: true }}
                  render={(field) => (
                    <Autocomplete
                      {...field}
                      id="channel-select"
                      options={channels ? channels : []}
                      onChange={(_, data) => {
                        field.onChange(data)
                        setValue(
                          'urlShortPageUrl',
                          `https://${data.channelUrl}`
                        )
                      }}
                      getOptionLabel={(option) => option.channelUrl}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.urlShortChannelUuid ? true : false}
                          helperText={
                            errors.urlShortChannelUuid
                              ? 'Channel is required!'
                              : null
                          }
                          {...params}
                          label="Channel"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="urlShortAdvertiserUuid"
                  control={control}
                  rules={{ required: true }}
                  render={(field) => (
                    <Autocomplete
                      {...field}
                      id="account-select"
                      options={advertisers ? advertisers : []}
                      onChange={(_, data) => {
                        field.onChange(data)
                        setValue(
                          'urlShortTargetUrl',
                          `https://${data.advertiserUrl}`
                        )
                      }}
                      getOptionLabel={(option) => option.advertiserUrl}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.urlShortAdvertiserUuid ? true : false}
                          helperText={
                            errors.urlShortAdvertiserUuid
                              ? 'Advertiser is required!'
                              : null
                          }
                          {...params}
                          label="Advertiser"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="urlShortName"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={(field) => (
                    <TextField
                      {...field}
                      error={errors.urlShortName ? true : false}
                      helperText={
                        errors.urlShortName ? 'Link name is required!' : null
                      }
                      margin="dense"
                      label="Link name"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="urlShortPageUrl"
                  control={control}
                  rules={{
                    pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
                  }}
                  render={(field) => (
                    <TextField
                      {...field}
                      error={errors.urlShortPageUrl ? true : false}
                      helperText={
                        errors.urlShortPageUrl ? 'Valid URL is required!' : null
                      }
                      margin="dense"
                      label="From channel url"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="urlShortTargetUrl"
                  control={control}
                  rules={{
                    pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
                  }}
                  render={(field) => (
                    <TextField
                      {...field}
                      error={errors.urlShortTargetUrl ? true : false}
                      helperText={
                        errors.urlShortTargetUrl ? 'Valid URL required!' : null
                      }
                      margin="dense"
                      label="To advertiser url"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Creating...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
