import React from 'react'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TableSortLabel,
  Icon,
  TextField,
  InputAdornment,
  Tooltip,
  Theme,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Pagination from '@material-ui/lab/Pagination'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../../elements/colors'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { InsightsDataType, TotalsDataType } from '../../types'
import IconButton from '@material-ui/core/IconButton'
import DownloadIcon from '@material-ui/icons/CloudDownload'

const useStyles = makeStyles({
  table: {
    maxHeight: '600px',
    // minHeight: '600px',
  },
  root: {
    marginTop: '5px',
    position: 'relative',
  },
  paperContainer: {
    padding: '16px',
    position: 'relative',
  },
  pill: {
    padding: '5px',
    fontWeight: 500,
    borderRadius: '5px',
  },
  pageviews: {
    color: colors.pageviews.primary,
    backgroundColor: colors.pageviews.secondary,
  },
  clicks: {
    color: colors.click.primary,
    backgroundColor: colors.click.secondary,
  },
  conversions: {
    color: colors.conversion.primary,
    backgroundColor: colors.conversion.secondary,
  },
  commission: {
    color: colors.commission.primary,
    backgroundColor: colors.commission.secondary,
  },
  tableCell: {
    borderBottom: 'none',
  },
  tableFooterCell: {
    borderBottom: 'none',
    fontWeight: 500,
    color: '#000',
    borderTop: '1px solid rgb(224, 224, 224)',
    fontSize: '14px',
  },
  tableFooter: {
    position: 'absolute',
    bottom: '55px',
  },
  backdrop: {
    position: 'absolute',
    zIndex: 3,
    color: '#282E67',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  tableText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
  },
})

const useStylesTooltip = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.primary.dark,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.dark,
    color: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    padding: 10,
    textAlign: 'center',
    marginTop: '10px',
  },
}))

type Props = {
  pageCount: number
  currentPage: number
  handlePageChange: (page: number) => void
  data: InsightsDataType[]
  isLoading: boolean
  handleSort: (val: string) => void
  sortBy: string
  sortDirection: 'asc' | 'desc'
  totals: TotalsDataType
  handleFilterClick: (grp: string, grpName: string) => void
  handleSearch: (val: string) => void
  handleCSVDownloadClick: () => void
}

export default function DataTable({
  pageCount,
  currentPage,
  handlePageChange,
  data,
  isLoading,
  handleSort,
  sortBy,
  sortDirection,
  totals,
  handleFilterClick,
  handleSearch,
  handleCSVDownloadClick,
}: Props) {
  const classes = useStyles()
  const tooltipClasses = useStylesTooltip()
  const [searchValue, setSearchValue] = React.useState('')
  const inputRef = React.useRef<HTMLInputElement>()

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
    handleSearch(event.target.value)
  }

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    handlePageChange(value)
  }

  const currencyCode = window.localStorage.getItem('publisherCurrency') || 'DKK'
  const formatter = new Intl.NumberFormat('da-DK')

  React.useEffect(() => {
    if (isLoading && searchValue) {
      setSearchValue('')
      if (inputRef.current !== undefined) {
        inputRef.current.value = ''
      }
    }
  }, [isLoading, searchValue])

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <Paper
          className={classes.paperContainer}
          elevation={0}
          variant="outlined"
        >
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            style={{ marginBottom: 10 }}
          >
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                type="search"
                id="go-error-search-field"
                margin="dense"
                onChange={handleSearchChange}
                inputRef={inputRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                aria-label="download"
                title="Download CSV"
                color="primary"
                onClick={handleCSVDownloadClick}
              >
                <DownloadIcon />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer className={classes.table}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '250px' }}>
                    <TableSortLabel
                      active={sortBy === 'grpName'}
                      direction={sortBy === 'grpName' ? sortDirection : 'asc'}
                      onClick={() => handleSort('grpName')}
                    >
                      Group
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'pageviews'}
                      direction={sortBy === 'pageviews' ? sortDirection : 'asc'}
                      onClick={() => handleSort('pageviews')}
                    >
                      Pageviews
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'clicks'}
                      direction={sortBy === 'clicks' ? sortDirection : 'asc'}
                      onClick={() => handleSort('clicks')}
                    >
                      Clicks
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'ctr'}
                      direction={sortBy === 'ctr' ? sortDirection : 'asc'}
                      onClick={() => handleSort('ctr')}
                    >
                      CTR
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'conversions'}
                      direction={
                        sortBy === 'conversions' ? sortDirection : 'asc'
                      }
                      onClick={() => handleSort('conversions')}
                    >
                      Conversions
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'cr'}
                      direction={sortBy === 'cr' ? sortDirection : 'asc'}
                      onClick={() => handleSort('cr')}
                    >
                      CR
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'sale'}
                      direction={sortBy === 'sale' ? sortDirection : 'asc'}
                      onClick={() => handleSort('sale')}
                    >
                      Sale
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right" width={160}>
                    <TableSortLabel
                      active={sortBy === 'commission'}
                      direction={
                        sortBy === 'commission' ? sortDirection : 'asc'
                      }
                      onClick={() => handleSort('commission')}
                    >
                      Commission
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'epc'}
                      direction={sortBy === 'epc' ? sortDirection : 'asc'}
                      onClick={() => handleSort('epc')}
                    >
                      EPC
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'epm'}
                      direction={sortBy === 'epm' ? sortDirection : 'asc'}
                      onClick={() => handleSort('epm')}
                    >
                      EPM
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, idx) => (
                  <TableRow
                    key={row.grp}
                    hover
                    style={
                      idx % 2
                        ? { background: 'rgba(0, 0, 150, 0.025)' }
                        : { background: 'white' }
                    }
                  >
                    <TableCell className={classes.tableCell} width={250}>
                      <div style={{ display: 'flex' }}>
                        <Tooltip
                          title={row.grpName}
                          classes={tooltipClasses}
                          arrow
                        >
                          <div
                            className={classes.tableText}
                            style={{ width: '240px', paddingRight: '5px' }}
                          >
                            {row.grpName}
                          </div>
                        </Tooltip>
                        <Icon
                          onClick={() =>
                            handleFilterClick(row.grp, row.grpName)
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          filter_list
                        </Icon>
                      </div>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <span
                        className={[classes.pageviews, classes.pill].join(' ')}
                      >
                        {formatter.format(row.pageviews)}
                      </span>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <span
                        className={[classes.clicks, classes.pill].join(' ')}
                      >
                        {formatter.format(row.clicks)}
                      </span>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <span>{`${formatter.format(row.ctr)} %`}</span>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <span
                        className={[classes.conversions, classes.pill].join(
                          ' '
                        )}
                      >
                        {formatter.format(row.conversions)}
                      </span>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <span>{`${formatter.format(row.cr)} %`}</span>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <span
                        style={{ display: 'inline-block', width: '130px' }}
                      >{`${currencyCode} ${formatter.format(row.sale)}`}</span>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <span style={{ display: 'inline-block', width: '120px' }}>
                        <span
                          className={[classes.commission, classes.pill].join(
                            ' '
                          )}
                        >
                          {`${currencyCode} ${formatter.format(
                            row.commission
                          )}`}
                        </span>
                      </span>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <span
                        style={{ display: 'inline-block', width: '80px' }}
                      >{`${currencyCode} ${formatter.format(row.epc)}`}</span>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <span
                        style={{ display: 'inline-block', width: '90px' }}
                      >{`${currencyCode} ${formatter.format(row.epm)}`}</span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell className={classes.tableFooterCell} width={250}>
                    Total
                  </TableCell>
                  <TableCell align="right" className={classes.tableFooterCell}>
                    <span
                      className={[classes.pageviews, classes.pill].join(' ')}
                    >
                      {formatter.format(totals.pageviews)}
                    </span>
                  </TableCell>
                  <TableCell align="right" className={classes.tableFooterCell}>
                    <span className={[classes.clicks, classes.pill].join(' ')}>
                      {formatter.format(totals.clicks)}
                    </span>
                  </TableCell>
                  <TableCell align="right" className={classes.tableFooterCell}>
                    <span>{`${formatter.format(totals.ctr)} %`}</span>
                  </TableCell>
                  <TableCell align="right" className={classes.tableFooterCell}>
                    <span
                      className={[classes.conversions, classes.pill].join(' ')}
                    >
                      {formatter.format(totals.conversions)}
                    </span>
                  </TableCell>
                  <TableCell align="right" className={classes.tableFooterCell}>
                    <span>{`${formatter.format(totals.cr)} %`}</span>
                  </TableCell>
                  <TableCell align="right" className={classes.tableFooterCell}>
                    <span>{`${currencyCode} ${formatter.format(
                      totals.sale
                    )}`}</span>
                  </TableCell>
                  <TableCell align="right" className={classes.tableFooterCell}>
                    <span
                      className={[classes.commission, classes.pill].join(' ')}
                    >
                      {`${currencyCode} ${formatter.format(totals.commission)}`}
                    </span>
                  </TableCell>
                  <TableCell align="right" className={classes.tableFooterCell}>
                    <span>{`${currencyCode} ${formatter.format(
                      totals.epc
                    )}`}</span>
                  </TableCell>
                  <TableCell align="right" className={classes.tableFooterCell}>
                    <span>{`${currencyCode} ${formatter.format(
                      totals.epm
                    )}`}</span>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <div
            style={{
              width: '100%',
              marginTop: '10px',
              display: pageCount ** pageCount > 1 ? 'flex' : 'none',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={pageCount}
              variant="outlined"
              shape="rounded"
              color="primary"
              page={currentPage}
              onChange={handlePagination}
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}
