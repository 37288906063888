import React, { useState } from 'react'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextTruncate from 'react-text-truncate'
import { ConversionDataType } from '../../types'
import UserJourney from '../UserJourney'

type Props = {
  data: ConversionDataType[]
}

const useStyles = makeStyles({
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  avatar: {
    height: 25,
    width: 25,
    fontSize: '1rem',
    marginRight: 5,
  },
  text: {
    width: 90,
    fontSize: 11,
  },
  wrapper: {
    fontSize: 11,
  },
  table: {
    maxHeight: '500px',
    minHeight: '500px',
  },
  root: {
    marginTop: '22px',
  },
  paperContainer: {
    padding: '16px',
  },
  tableCell: {
    borderBottom: 'none',
  },
  title: {
    marginBottom: '10px',
    textAlign: 'center',
  },
  noData: {
    display: 'block',
    width: '100%',
    fontSize: '12px',
    margin: '150px 100px',
    textAlign: 'center',
    color: '#555',
  },
  row: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

export default function ConversionsContainer({ data }: Props) {
  const classes = useStyles()
  const [activeClick, setActiveClick] = useState('')
  return (
    <>
      <UserJourney clickUuid={activeClick} setClickUuid={setActiveClick} />
      <Grid item xs={6}>
        <Paper
          elevation={0}
          variant="outlined"
          className={classes.paperContainer}
        >
          <Typography variant="subtitle2" className={classes.title}>
            Latest conversions
          </Typography>
          <TableContainer className={classes.table}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Advertiser</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Commission</TableCell>
                </TableRow>
              </TableHead>
              {data.length ? (
                <TableBody>
                  {data.map((row, idx) => (
                    <TableRow
                      key={row.clickUuid}
                      style={
                        idx % 2
                          ? { background: 'rgba(0, 0, 150, 0.025)' }
                          : { background: 'white' }
                      }
                      className={classes.row}
                      onClick={() => setActiveClick(row.clickUuid)}
                    >
                      <TableCell className={classes.tableCell}>
                        <div className={classes.avatarContainer}>
                          <Avatar
                            className={classes.avatar}
                            sizes="15"
                            alt={row.advertiser}
                            src={row.favicon}
                          >
                            {row.advertiser.charAt(0).toLowerCase()}
                          </Avatar>
                          <div className={classes.text}>
                            <TextTruncate line={1} text={row.advertiser} />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div className={classes.text}>
                          <TextTruncate line={1} text={row.source} />
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div className={classes.text}>
                          <TextTruncate line={1} text={row.timeStamp} />
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div className={classes.text}>
                          <TextTruncate line={1} text={row.commission} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <Typography className={classes.noData} variant="body1">
                  No Conversions Found
                </Typography>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </>
  )
}
