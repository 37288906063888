import React from 'react'
import { Grid, Typography } from '@material-ui/core'

type Props = {
  count: number
}

export default function Header({ count }: Props) {
  return (
    <Grid container spacing={0} justify="space-between" alignItems="center">
      <Grid item xs={9}>
        <Typography variant="h5" data-testid="page-heading">
          Channels
        </Typography>
        <Typography gutterBottom color="textSecondary">
          There are {count} channels.
        </Typography>
      </Grid>
    </Grid>
  )
}
