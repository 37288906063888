import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { SingleItemType } from './types'
import Logo from '../../../components/Header/Logo'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperRoot: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    chipsRoot: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    pdfButton: {
      position: 'absolute',
      left: theme.spacing(2),
      top: theme.spacing(2),
      color: theme.palette.grey[500],
    },
    dividerSpace: {
      marginBottom: theme.spacing(1),
    },
  })
)

const formatter = new Intl.NumberFormat('da-DK')

type PropsType = {
  record: SingleItemType
  open: boolean
  handleClose: () => void
  handlePDFDownloadClick: (uuid: string) => void
}

export function View({
  open,
  handleClose,
  record,
  handlePDFDownloadClick,
}: PropsType) {
  const classes = useStyles()

  const close = () => {
    handleClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      disableBackdropClick={true}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={close}
        >
          <CloseIcon />
        </IconButton>
        <Button
          aria-label="download"
          className={classes.pdfButton}
          onClick={() =>
            handlePDFDownloadClick(record?.paymentNote.paymentNoteUuid ?? '')
          }
          variant="outlined"
        >
          Download as PDF
        </Button>
      </DialogTitle>
      <DialogContent>
        <Grid container justify="space-between" style={{ marginTop: '40px' }}>
          <Grid item xs={6}>
            <Logo />
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              Payment Note: {record?.paymentNote.paymentNoteIdentifier}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="space-between">
          <Grid item xs={12}>
            <Typography align="right">
              Date: {record?.paymentNote.paymentNoteDate}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="space-between">
          <Grid item xs={12}>
            <Typography align="right">
              Period: {record?.paymentNote.paymentNotePeriodStartDate} -{' '}
              {record?.paymentNote.paymentNotePeriodEndDate}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="space-between" style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            <Typography align="left">VAT number: 4007431</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              VAT number: {record?.client.clientVatNumber}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="space-between">
          <Grid item xs={6}>
            <Typography align="left">Heylink ApS</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {record?.client.clientCompanyName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="space-between">
          <Grid item xs={6}>
            <Typography align="left">Kristianiagade 1</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {record?.client.clientAddress}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="space-between">
          <Grid item xs={6}>
            <Typography align="left">2100 København K</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {record?.client.clientZipCode} {record?.client.clientCity}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="space-between">
          <Grid item xs={6}>
            <Typography align="left">DK</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {record?.client.clientCountryCode}
            </Typography>
          </Grid>
        </Grid>
        {record?.paymentNoteItems.length ? (
          <Grid container style={{ margin: '40px 0' }}>
            <TableContainer style={{ maxHeight: '500px' }}>
              <Table size="small" aria-label="details-table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Advertiser</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Commission</TableCell>
                    <TableCell align="right">Currency</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record?.paymentNoteItems.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th" scope="row">
                        {row.advertiser.advertiserName}
                      </TableCell>
                      <TableCell align="right">
                        {formatter.format(
                          row.paymentNoteItem.paymentNoteItemQuantity
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {formatter.format(
                          row.paymentNoteItem.paymentNoteItemCommission
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {row.paymentNoteItem.paymentNoteItemCurrencyCode}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : null}
        {record?.paymentNoteTransactions.length ? (
          <Grid container style={{ margin: '30px 0' }}>
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ margin: '10px 10px' }}
            >
              Other services
            </Typography>
            <TableContainer style={{ maxHeight: '300px' }}>
              <Table size="small" aria-label="details-table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Text</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Currency</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record?.paymentNoteTransactions.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th" scope="row">
                        {row.transactionText}
                      </TableCell>
                      <TableCell align="right">
                        {formatter.format(row.transactionAmount)}
                      </TableCell>
                      <TableCell align="right">
                        {row.transactionCurrencyCode}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : null}
        <hr />
        <Grid container style={{ margin: '10px 0' }}>
          <Grid item xs={6}>
            <Typography>Sub total</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {formatter.format(record?.paymentNote.paymentNoteSubTotal ?? 0)}{' '}
              {record?.paymentNote.paymentNoteCurrencyCode}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>VAT</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {formatter.format(record?.paymentNote.paymentNoteVat ?? 0)}{' '}
              {record?.paymentNote.paymentNoteCurrencyCode}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Total</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {formatter.format(record?.paymentNote.paymentNoteTotal ?? 0)}{' '}
              {record?.paymentNote.paymentNoteCurrencyCode}
            </Typography>
          </Grid>
        </Grid>
        <hr />
      </DialogContent>
    </Dialog>
  )
}
