import React, { ReactNode, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  CardHeader,
  Icon,
} from '@material-ui/core/'

const useStyles = makeStyles({
  title: {
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.8)',
    textTransform: 'uppercase',
    marginBottom: 8,
    fontWeight: 400,
  },
  avatar: {
    color: 'green',
    backgroundColor: 'black',
    marginLeft: '16px',
  },
  value: {
    fontSize: 28,
    fontWeight: 500,
    marginBottom: 5,
    textAlign: 'center',
  },

  subTxt: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    display: 'inline-block',
  },
  subValue: {
    fontSize: '14px',
    display: 'inline-block',
    color: '#000',
    marginLeft: '5px',
    borderBottom: '1px dashed #000',
    cursor: 'pointer',
  },
  tooltip: {
    zIndex: 9999,
    position: 'absolute',
    bottom: -10,
    backgroundColor: 'rgba(0,0,0, 0.9)',
    borderRadius: '6px',
    color: '#fff',
    width: '90%',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '5px',
    textAlign: 'center',
  },
})

type Props = {
  icon: string
  title: string
  value: string
  avatarColor: string
  avatarBackground: string
  subTxt: string
  subValue: string
  tooltip: ReactNode
}

export default function CardContainer({
  icon,
  title,
  value,
  avatarBackground,
  avatarColor,
  subTxt,
  subValue,
  tooltip,
}: Props) {
  const classes = useStyles()
  const [show, setShow] = useState(false)
  return (
    <Grid item xs={3} style={{ position: 'relative' }}>
      <Card variant="outlined">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                className={classes.avatar}
                style={{
                  color: avatarColor,
                  backgroundColor: avatarBackground,
                }}
              >
                <Icon>{icon}</Icon>
              </Avatar>
            }
          />
          <CardContent>
            <div style={{ textAlign: 'center' }}>
              <Typography variant="h3" className={classes.title}>
                {title}
              </Typography>
            </div>
            <Typography variant="h3" className={classes.value}>
              {value}
            </Typography>
            <div
              style={{ textAlign: 'center', cursor: 'pointer' }}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              <Typography variant="body1" className={classes.subTxt}>
                {subTxt}:
              </Typography>
              <Typography variant="body1" className={classes.subValue}>
                {subValue}
              </Typography>
            </div>
            {show ? (
              <div className={classes.tooltip}>
                <Typography
                  variant="body1"
                  style={{ fontSize: '12px', fontWeight: 500 }}
                >
                  {tooltip}
                </Typography>
              </div>
            ) : null}
          </CardContent>
        </div>
      </Card>
    </Grid>
  )
}
