import jwtDecode, { JwtPayload } from 'jwt-decode'
import { BASE_URL } from './config'

export const generateFile = (data: any, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  link.click()
  link.remove()
}

export async function makeCall(
  method: string,
  url: string,
  body = {},
  responseType = 'json'
) {
  const token = localStorage.getItem('publisherAuthKey') || ''
  const { exp } = jwtDecode<JwtPayload>(token)
  if (exp) {
    const expirationTime = exp * 1000 - 10000
    if (Date.now() >= expirationTime) {
      localStorage.removeItem('publisherAuthKey')
      window.location.assign('/login/')
    }
  }

  const bearer = `Bearer ${token}`
  const response = await fetch(`${BASE_URL}/${url}`, {
    method,
    headers: {
      Authorization: bearer,
      Accept: responseType === 'json' ? 'application/json' : responseType,
      'Content-Type': 'application/json',
    },
    ...(method !== 'GET' && { body: JSON.stringify(body) }),
  })
  if (response.status === 401) {
    localStorage.removeItem('publisherAuthKey')
    window.location.assign('/login/')
  }
  if (response.status === 200) {
    if (responseType === 'json') {
      const { data } = await response.json()
      return { data, status: response.status }
    } else {
      const blob = await response.blob()
      return { data: blob, status: response.status }
    }
  }

  const error = await response.json()
  return { data: error, status: response.status }
}
