import React from 'react'
import { GraphDataType } from '../../types'
import BarChart from './BarChartContainer'
import LineChart from './LineChartContainer'

type Props = {
  data: GraphDataType
}

export default function ChartContainer({ data }: Props) {
  if (data.lines.length) return <LineChart data={data.lines} />
  if (data.bars.length) return <BarChart data={data.bars} />
  return null
}
