import React from 'react'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Icon,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Pagination from '@material-ui/lab/Pagination'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { DataType } from '../types'
import TextTruncate from 'react-text-truncate'
import IconButton from '@material-ui/core/IconButton'
import DownloadIcon from '@material-ui/icons/CloudDownload'

const useStyles = makeStyles({
  table: {
    maxHeight: '600px',
  },
  root: {
    marginTop: '50px',
    position: 'relative',
  },
  paperContainer: {
    padding: '16px',
    position: 'relative',
  },
  pill: {
    padding: '5px',
    fontWeight: 500,
    borderRadius: '5px',
  },
  tableCell: {
    borderBottom: 'none',
  },
  tableFooterCell: {
    borderBottom: 'none',
    fontWeight: 500,
    color: '#000',
    borderTop: '1px solid rgb(224, 224, 224)',
    fontSize: '14px',
  },
  tableFooter: {
    position: 'absolute',
    bottom: '55px',
  },
  backdrop: {
    position: 'absolute',
    zIndex: 3,
    color: '#282E67',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
})

type Props = {
  pageCount: number
  currentPage: number
  handlePageChange: (page: number) => void
  data: DataType
  isLoading: boolean
  handleSort: (val: string) => void
  sortBy: string
  sortDirection: 'asc' | 'desc'
  handleFilterClick: (uuid: string) => void
  handleSearch: (val: string) => void
  handlePDFDownloadClick: (uuid: string) => void
  handleCSVDownloadClick: () => void
}

export default function DataTable({
  pageCount,
  currentPage,
  handlePageChange,
  data,
  isLoading,
  handleSort,
  sortBy,
  sortDirection,
  handleFilterClick,
  handleSearch,
  handlePDFDownloadClick,
  handleCSVDownloadClick,
}: Props) {
  const classes = useStyles()
  const [searchValue, setSearchValue] = React.useState('')
  const inputRef = React.useRef<HTMLInputElement>()

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
    handleSearch(event.target.value)
  }

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    handlePageChange(value)
  }

  const formatter = new Intl.NumberFormat('da-DK')

  React.useEffect(() => {
    if (isLoading && searchValue) {
      setSearchValue('')
      if (inputRef.current !== undefined) {
        inputRef.current.value = ''
      }
    }
  }, [isLoading, searchValue])

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <Paper
          className={classes.paperContainer}
          elevation={0}
          variant="outlined"
        >
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            style={{ marginBottom: 10 }}
          >
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                type="search"
                id="go-error-search-field"
                margin="dense"
                onChange={handleSearchChange}
                inputRef={inputRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                aria-label="download"
                title="Download CSV"
                color="primary"
                onClick={handleCSVDownloadClick}
              >
                <DownloadIcon />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer className={classes.table}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width={250}>
                    <TableSortLabel
                      active={sortBy === 'paymentNoteIdentifier'}
                      direction={
                        sortBy === 'paymentNoteIdentifier'
                          ? sortDirection
                          : 'asc'
                      }
                      onClick={() => handleSort('paymentNoteIdentifier')}
                    >
                      Identifier
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'paymentNoteDate'}
                      direction={
                        sortBy === 'paymentNoteDate' ? sortDirection : 'asc'
                      }
                      onClick={() => handleSort('paymentNoteDate')}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'paymentNotePeriodStartDate'}
                      direction={
                        sortBy === 'paymentNotePeriodStartDate'
                          ? sortDirection
                          : 'asc'
                      }
                      onClick={() => handleSort('paymentNotePeriodStartDate')}
                    >
                      Period
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'paymentNoteSubTotal'}
                      direction={
                        sortBy === 'paymentNoteSubTotal' ? sortDirection : 'asc'
                      }
                      onClick={() => handleSort('paymentNoteSubTotal')}
                    >
                      Subtotal
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'paymentNoteVat'}
                      direction={
                        sortBy === 'paymentNoteVat' ? sortDirection : 'asc'
                      }
                      onClick={() => handleSort('paymentNoteVat')}
                    >
                      VAT
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'paymentNoteTotal'}
                      direction={
                        sortBy === 'paymentNoteTotal' ? sortDirection : 'asc'
                      }
                      onClick={() => handleSort('paymentNoteTotal')}
                    >
                      Total
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortBy === 'paymentNoteCurrencyCode'}
                      direction={
                        sortBy === 'paymentNoteCurrencyCode'
                          ? sortDirection
                          : 'asc'
                      }
                      onClick={() => handleSort('paymentNoteCurrencyCode')}
                    >
                      Currency
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.length ? (
                  data.map(({ paymentNote }, idx) => (
                    <TableRow
                      key={paymentNote.paymentNoteUuid}
                      hover
                      style={
                        idx % 2
                          ? { background: 'rgba(0, 0, 150, 0.025)' }
                          : { background: 'white' }
                      }
                    >
                      <TableCell className={classes.tableCell} width={200}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: 200 }}>
                            <TextTruncate
                              line={1}
                              text={paymentNote.paymentNoteIdentifier}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <span>{paymentNote.paymentNoteDate}</span>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <span>
                          {paymentNote.paymentNotePeriodStartDate} <br />
                          {paymentNote.paymentNotePeriodEndDate}
                        </span>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <span>
                          {formatter.format(paymentNote.paymentNoteSubTotal)}
                        </span>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <span>
                          {formatter.format(paymentNote.paymentNoteVat)}
                        </span>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <span>
                          {' '}
                          {formatter.format(paymentNote.paymentNoteTotal)}
                        </span>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <span>{paymentNote.paymentNoteCurrencyCode}</span>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <Icon
                          onClick={() =>
                            handleFilterClick(paymentNote.paymentNoteUuid)
                          }
                          style={{ cursor: 'pointer' }}
                          color="primary"
                        >
                          visibility
                        </Icon>
                        <Icon
                          onClick={() =>
                            handlePDFDownloadClick(paymentNote.paymentNoteUuid)
                          }
                          style={{ cursor: 'pointer', marginLeft: '15px' }}
                          color="primary"
                        >
                          picture_as_pdf
                        </Icon>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      style={{ border: 'none', padding: '30px 0' }}
                      colSpan={7}
                      align="center"
                    >
                      No payment notes found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              width: '100%',
              marginTop: '10px',
              display: pageCount ** pageCount > 1 ? 'flex' : 'none',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={pageCount}
              variant="outlined"
              shape="rounded"
              color="primary"
              page={currentPage}
              onChange={handlePagination}
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}
