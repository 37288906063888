import { DataType } from './types'
export const initialData: DataType = {
  graphs: {
    lines: [],
    bars: [],
  },
  insights: [],
  totals: {
    commission: 0,
    sale: 0,
    clicks: 0,
    conversions: 0,
    pageviews: 0,
    epc: 0,
    ctr: 0,
    cr: 0,
    epm: 0,
  },
}
