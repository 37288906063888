import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Icon, Tooltip } from '@material-ui/core/'
import { GroupFiltersType } from '../../types';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: '100%',
    marginTop: '22px',
    marginBottom: '22px',
    minWidth: '100%',
  },
  tabsRoot: {
    borderBottom: '2px solid #e8e8e8',
  },
  tab: {
    minWidth: 10,
  },

  subTab: {
    width: 8,
    fontSize: '12px',
  },
})

type Props = {
  handleGroupChange: (group: string) => void
  group: string
  groupFilters: GroupFiltersType
}

export default function IconLabelTabs({ handleGroupChange, group, groupFilters }: Props) {
  const classes = useStyles()
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    handleGroupChange(newValue)
  }

  return (
    <>
      <div className={classes.root}>
        <Tabs
          value={
            ['date', 'week', 'month', 'year'].includes(group) ? 'date' : group
          }
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="group-tabs"
          className={classes.tabsRoot}
        >
          <Tab
            label="Date"
            icon={<Icon>date_range</Icon>}
            className={classes.tab}
            value="date"
          />
          <Tab
            label="Advertisers"
            icon={<Icon>store</Icon>}
            className={classes.tab}
            value="advertiser"
          />
          <Tab
            label="Channel"
            icon={<Icon>desktop_mac</Icon>}
            className={classes.tab}
            value="channel"
          />
          <Tab
            style={{ pointerEvents: "auto" }}
            label={
              groupFilters.channelUuid === '' ?
                <Tooltip title="Select a channel to unlock this group">
                  <span>Page</span>
                </Tooltip>
              :
                'Page'
            }
            icon={<Icon>pageview</Icon>}
            className={classes.tab}
            value="page"
            disabled={groupFilters.channelUuid === '' ? true : false}
          />
          <Tab
            label="Device"
            icon={<Icon>devices</Icon>}
            className={classes.tab}
            value="device"
          />
          <Tab
            label="Referrer"
            icon={<Icon>subdirectory_arrow_right</Icon>}
            className={classes.tab}
            value="referer"
          />
        </Tabs>
      </div>
      {['date', 'week', 'month', 'year'].includes(group) ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <Tabs
            value={group}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            aria-label="group-tabs"
            className={classes.tabsRoot}
          >
            <Tab label="Date" value="date" className={classes.subTab} />
            <Tab label="Week" value="week" className={classes.subTab} />
            <Tab label="Month" value="month" className={classes.subTab} />
            <Tab label="Year" value="year" className={classes.subTab} />
          </Tabs>
        </div>
      ) : null}
    </>
  )
}
