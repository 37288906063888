import React, { useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { makeCall } from '../../../utils/api'
import { BaseModalType, BaseFormType } from './types'

export function Update({
  isOpen,
  handleClose,
  refresh,
  record,
}: BaseModalType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<BaseFormType>()

  useEffect(() => {
    if (record) {
      reset({
        urlShortPageUrl: record.urlShort.urlShortPageUrl,
        urlShortTargetUrl: record.urlShort.urlShortTargetUrl,
        urlShortName: record.urlShort.urlShortName,
      })
    }
  }, [record, reset])

  const onSubmit = async (data: BaseFormType) => {
    if (record) {
      await makeCall(
        'PUT',
        `tools/urlshorts/${record.urlShort.urlShortCode}`,
        data
      )
    }
    handleClose()
    refresh()
  }

  return (
    <>
      <Dialog
        open={isOpen}
        disableBackdropClick={true}
        onEscapeKeyDown={handleClose}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Update URL</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ marginBottom: 20 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="urlShortName"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={(field) => (
                    <TextField
                      {...field}
                      error={errors.urlShortName ? true : false}
                      helperText={
                        errors.urlShortName ? 'Link name is required!' : null
                      }
                      margin="dense"
                      label="Link name"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="urlShortPageUrl"
                  control={control}
                  rules={{
                    pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
                  }}
                  render={(field) => (
                    <TextField
                      {...field}
                      error={errors.urlShortPageUrl ? true : false}
                      helperText={
                        errors.urlShortPageUrl ? 'Valid URL is required!' : null
                      }
                      margin="dense"
                      label="From channel url"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="urlShortTargetUrl"
                  control={control}
                  rules={{
                    pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
                  }}
                  render={(field) => (
                    <TextField
                      {...field}
                      error={errors.urlShortTargetUrl ? true : false}
                      helperText={
                        errors.urlShortTargetUrl ? 'Valid URL required!' : null
                      }
                      margin="dense"
                      label="To advertiser url"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
