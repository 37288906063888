import React from 'react'
import { Grid, TextField } from '@material-ui/core'

type Props = {
  search: string
  setSearch: (val: string) => void
}

export default function Filters({ search, setSearch }: Props) {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          id="search-channels"
          label="Search"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          fullWidth
          size="small"
        />
      </Grid>
    </Grid>
  )
}
