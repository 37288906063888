import React from 'react'
import { makeStyles } from '@material-ui/core'
import { colors } from '../../../../elements/colors'
type Props = {
  data: {
    paid: number
    pendingNoPaymentReceived: number
    pendingPaymentReceived: number
    rejected: number
  }
  isLast: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    cursor: 'pointer',
    '& > div': {
      height: 12,
    },
    '&:hover > span': {
      display: 'block',
    },
  },
  pendingNo: {
    backgroundColor: colors.click.primary,
  },
  pending: {
    backgroundColor: colors.conversion.primary,
  },
  reject: {
    backgroundColor: theme.palette.secondary.main,
  },
  paid: {
    backgroundColor: colors.commission.primary,
  },
  tooltip: {
    display: 'none',
    border: '1px solid #ccc',
    borderRadius: '5px',
    position: 'absolute',
    zIndex: 99999999999,
    top: -50,
    left: -190,
    width: '185px',
    background: 'white',
    padding: '10px',
    '& > span': {
      display: 'block',
      fontWeight: 'bold',
      paddingTop: 3,
      paddingBottom: 3,
    },
    '&.isLast': {
      top: -100,
    },
    '& > span:nth-child(1)': {
      color: colors.click.primary,
    },
    '& > span:nth-child(2)': {
      color: colors.conversion.primary,
    },
    '& > span:nth-child(3)': {
      color: theme.palette.secondary.main,
    },
    '& > span:nth-child(4)': {
      color: colors.commission.primary,
    },
  },
}))

export default function StatusBar({ data, isLast }: Props) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div
        className={classes.pendingNo}
        style={{ width: `${data.pendingNoPaymentReceived}%` }}
      >
        &nbsp;
      </div>
      <div
        className={classes.pending}
        style={{ width: `${data.pendingPaymentReceived}%` }}
      >
        &nbsp;
      </div>
      <div className={classes.reject} style={{ width: `${data.rejected}%` }}>
        &nbsp;
      </div>
      <div className={classes.paid} style={{ width: `${data.paid}%` }}>
        &nbsp;
      </div>
      <span className={`${classes.tooltip} ${isLast ? 'isLast' : ''}`}>
        <span>Pending: {data.pendingNoPaymentReceived}%</span>
        <span>Ready for payout: {data.pendingPaymentReceived}%</span>
        <span>Rejected: {data.rejected}%</span>
        <span>Paid out: {data.paid}%</span>
      </span>
    </div>
  )
}
