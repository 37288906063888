import React from 'react'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'

type PropsType = {
  id?: string
  placeholder: any
  height: string
  handleChange?: (payload: any) => void
  viewOnly?: boolean
}

export default function JsonEditor({
  id = 'jsonEditor',
  placeholder,
  height,
  handleChange,
  viewOnly = true,
}: PropsType) {
  return (
    <JSONInput
      id={id}
      locale={locale}
      placeholder={placeholder}
      colors={{
        background: '#183055',
        default: '#fff',
        string: '#71a7ff',
        colon: '#fff',
        keys: '#71a7ff',
        primitive: '#ff9d3d',
        number: '#ff9d3d',
      }}
      style={{
        labels: {
          color: '#eee',
        },
        contentBox: {
          fontSize: '14px',
        },
        body: {
          fontSize: '14px',
        },
      }}
      height={height}
      width="100%"
      onChange={handleChange ? handleChange : null}
      viewOnly={viewOnly}
    />
  )
}
