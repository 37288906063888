import { lazy } from 'react'
import Dashboard from './screens/Dashboard'
import Insights from './screens/Insights'
import Advertisers from './screens/Advertisers'
import Channels from './screens/Channels'
import PaymentNotes from './screens/Finance/PaymentNotes'
import FinanceStatusReport from './screens/Finance/StatusReport'
import URLShortener from './screens/Tools/URLShortener'
import Webhooks from './screens/Tools/Webhooks'
const FourOFour = lazy(() => import('./screens/FourOFour'))

const routes = [
  {
    title: 'Dashboard',
    path: '/',
    exact: true,
    strict: true,
    component: Dashboard,
  },
  {
    title: 'Insights',
    path: '/insights',
    exact: true,
    strict: true,
    component: Insights,
  },

  {
    title: 'Advertisers',
    path: '/advertisers',
    exact: true,
    strict: true,
    component: Advertisers,
  },

  {
    title: 'Channels',
    path: '/channels',
    exact: true,
    strict: true,
    component: Channels,
  },
  {
    title: 'Payment Notes',
    path: '/finance/payment-notes',
    exact: true,
    strict: true,
    component: PaymentNotes,
  },
  {
    title: 'Status Report',
    path: '/finance/status-report',
    exact: true,
    strict: true,
    component: FinanceStatusReport,
  },
  {
    title: 'URL shortener',
    path: '/tools/url-shortener',
    exact: true,
    strict: true,
    component: URLShortener,
  },
  {
    title: 'Webhooks',
    path: '/tools/webhooks',
    exact: true,
    strict: true,
    component: Webhooks,
  },
  {
    title: 'FourOFour',
    component: FourOFour,
  },
]

export default routes
