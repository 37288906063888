import format from 'date-fns/format'

export function formatDashboardData(data: any) {
  const currencyCode = window.localStorage.getItem('publisherCurrency') || 'DKK'
  const formatter = new Intl.NumberFormat('da-DK')

  const cardsData = {
    pageviews: formatter.format(data.totals.counts.pageviewsCount),
    clicks: formatter.format(data.totals.counts.clicksCount),
    conversions: formatter.format(data.totals.counts.conversionsCount),
    commission: `${currencyCode} ${formatter.format(
      data.totals.sums.commissionSum
    )}`,
    epm: `${currencyCode} ${formatter.format(
      data.totals.performanceIndex.epm
    )}`,
    ctr: `${formatter.format(data.totals.performanceIndex.ctr)} %`,
    cr: `${formatter.format(data.totals.performanceIndex.cr)} %`,
    epc: `${currencyCode} ${formatter.format(
      data.totals.performanceIndex.epc
    )}`,
  }
  const chartData = data.groups.map((row: any) => ({
    date:
      row.grp.length === 10
        ? format(new Date(row.grp), 'dd-LLL-yy')
        : `${row.grp.slice(11)}:00`,
    pageviews: row.counts.pageviewsCount,
    clicks: row.counts.clicksCount,
    conversions: row.counts.conversionsCount,
    commission: row.sums.commissionSum,
    currencies: row.commissions,
  }))

  const channelsData = data.channels.map((row: any) => ({
    channelUuid: row.channelUuid,
    channelUrl: row.channelUrl,
    pageviews: formatter.format(row.counts.pageviewsCount),
    clicks: formatter.format(row.counts.clicksCount),
    conversions: formatter.format(row.counts.conversionsCount),
    commission: `${currencyCode} ${formatter.format(row.sums.commissionSum)}`,
  }))

  return { cardsData, chartData, channelsData }
}

export function formatClicksData(data: any) {
  return data.clicks.map((row: any) => ({
    clickUuid: row.click.clickUuid,
    timeStamp: row.click.clickDatetime,
    source: row.channel.channelUrl,
    advertiser: row.advertiser.advertiserUrl,
    favicon: row.advertiser.advertiserFavicon,
  }))
}

export function formatConversionsData(data: any) {
  const formatter = new Intl.NumberFormat('da-DK')
  return data.conversions.map((row: any) => ({
    clickUuid: row.conversion.conversionClickUuid,
    timeStamp: row.conversion.conversionDatetime,
    source: row.channel.channelUrl,
    advertiser: row.advertiser.advertiserUrl,
    favicon: row.advertiser.advertiserFavicon,
    commission: `${row.conversion.conversionCurrencyCode} ${formatter.format(
      row.conversion.conversionCommission
    )} `,
  }))
}
