import React, { useState } from 'react'
import { Grid, Button, Paper, makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  FiltersType,
  Channel,
  Currency,
  GenericValue,
  CurrencyValue,
  ChannelValue,
} from './types'
import { TimeGroup, Years } from './initialData'

type Props = {
  onSubmit: (payload: FiltersType) => void
  channels: Channel[]
  currencies: Currency[]
  isLoading: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
}))

export function Filters({ onSubmit, channels, currencies, isLoading }: Props) {
  const classes = useStyles()
  const currentYear = new Date().getFullYear().toString()
  const [selectedTimeGroup, setSelectedTimeGroup] = useState<GenericValue>({
    key: 'month',
    value: 'Month',
  })
  const [selectedYear, setSelectedYear] = useState<GenericValue>({
    key: currentYear,
    value: currentYear,
  })
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyValue>(null)
  const [selectedChannel, setSelectedChannel] = useState<ChannelValue>(null)

  const handleGenerateClick = () => {
    const payload: FiltersType = {
      timeGroupBy: selectedTimeGroup?.key ?? 'month',
      year: Number(selectedYear?.key ?? currentYear),
      filters: {},
    }
    if (selectedChannel) {
      payload.filters.channelUuid = selectedChannel?.channelUuid
    }
    if (selectedCurrency) {
      payload.filters.currencyCode = selectedCurrency.currencyCode
    }
    onSubmit(payload)
  }

  return (
    <Paper variant="outlined" className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={2}>
          <Autocomplete
            id="timeGroup-select"
            options={TimeGroup}
            getOptionLabel={(option) => option.value}
            onChange={(_, value) => setSelectedTimeGroup(value)}
            defaultValue={selectedTimeGroup}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Time group"
                  defaultValue={selectedTimeGroup?.value}
                  variant="outlined"
                  margin="dense"
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            id="year-select"
            options={Years}
            getOptionLabel={(option) => option.value}
            onChange={(_, value) => setSelectedYear(value)}
            defaultValue={selectedYear}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  defaultValue={selectedYear?.value}
                  label="Year"
                  variant="outlined"
                  margin="dense"
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="currency-select"
            options={currencies}
            getOptionLabel={(option) => option.currencyCode}
            onChange={(_, value) => setSelectedCurrency(value)}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Currency"
                  variant="outlined"
                  margin="dense"
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="channel-select"
            options={channels}
            getOptionLabel={(option) => option.channelName}
            onChange={(_, value) => setSelectedChannel(value)}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Channel"
                  variant="outlined"
                  margin="dense"
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={handleGenerateClick}
            variant="contained"
            color="primary"
            fullWidth
            disabled={isLoading}
          >
            Generate
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}
