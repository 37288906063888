import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Container = styled(motion.div)`
  /* max-width: 1280px;
  margin: 30px auto;
  min-width: 1280px;
  padding: 0 20px; */
  margin: 40px;
  margin-left: 340px;
  min-width: 1080px;
`
