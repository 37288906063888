import React, { useState, useEffect } from 'react'
import endOfMonth from 'date-fns/endOfMonth'
import startOfYear from 'date-fns/startOfYear'
import { Container } from '../style'
import Loader from '../../../components/Loader/'
import { makeCall, generateFile } from '../../../utils/api'
import format from 'date-fns/format'
import { Grid, Typography } from '@material-ui/core'
import Datepicker from '../../Dashboard/Components/Datepicker'
import { DataType, SingleItemType } from './types'
import Table from './Table'
import { View } from './View'
import { orderBy } from 'lodash'

export default function PaymentNotes() {
  const [isLoading, setIsLoading] = useState(true)
  const [dateObj, setDateObj] = useState({
    fromDate: format(startOfYear(new Date()), 'yyyy-MM-dd'),
    toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  })
  const [data, setData] = useState<DataType>(null)
  const [item, setItem] = useState<SingleItemType>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [sortBy, setSortBy] = useState('grp')
  const [sortDirection, setSortDirection] = useState<'desc' | 'asc'>('asc')
  const [searchVal, setSearchVal] = useState('')

  const PER_PAGE = 30

  const setDate = (start: Date, end: Date) => {
    setDateObj({
      fromDate: format(start, 'yyyy-MM-dd'),
      toDate: format(end, 'yyyy-MM-dd'),
    })
    setIsLoading(true)
  }

  useEffect(() => {
    const getData = async () => {
      const response = await makeCall(
        'GET',
        `finance/paymentnotes?fromDate=${dateObj.fromDate}&toDate=${dateObj.toDate}`
      )
      setData(response.data)
      setIsLoading(false)
    }
    getData()
    setPageCount(0 / PER_PAGE)
    setCurrentPage(1)
    setSearchVal('')
  }, [dateObj])

  const handleFilterClick = async (uuid: string) => {
    setIsLoading(true)
    const response = await makeCall('GET', `finance/paymentnotes/${uuid}`)
    setIsLoading(false)
    setItem(response.data)
  }

  const handlePDFDownloadClick = async (uuid: string) => {
    if (!uuid) return
    setIsLoading(true)
    const response = await makeCall(
      'GET',
      `finance/paymentnotes/${uuid}.pdf`,
      {},
      'application/pdf'
    )
    if (response.data) {
      generateFile(response.data, `${uuid}.pdf`)
    }
    setIsLoading(false)
  }

  const openAsCSV = async () => {
    if (data && data.length) {
      setIsLoading(true)
      const response = await makeCall(
        'GET',
        `finance/paymentnotes?fromDate=${dateObj.fromDate}&toDate=${dateObj.toDate}`,
        {},
        'text/csv'
      )
      if (response.data) {
        generateFile(response.data, `finance-payment-notes.csv`)
      }
      setIsLoading(false)
    }
  }

  const getSearchedData = () => {
    let searchedData = data
    if (searchVal && data) {
      searchedData = data.filter(
        (row) =>
          row.paymentNote.paymentNoteIdentifier
            .toLowerCase()
            .includes(searchVal) ||
          row.paymentNote.paymentNoteDate.toLowerCase().includes(searchVal) ||
          row.paymentNote.paymentNoteCurrencyCode
            .toLowerCase()
            .includes(searchVal)
      )
    }
    return searchedData
  }

  const handleSort = (newSort: string) => {
    let newSortDirection: 'asc' | 'desc' = 'asc'
    if (sortBy === newSort) {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }
    const sortedData = orderBy(data, `paymentNote.${newSort}`, newSortDirection)
    setSortBy(newSort)
    setSortDirection(newSortDirection)
    setData(sortedData)
  }

  return (
    <>
      <View
        record={item}
        open={item ? true : false}
        handleClose={() => setItem(null)}
        handlePDFDownloadClick={handlePDFDownloadClick}
      />
      <Loader loading={isLoading} />
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <Grid container spacing={0} justify="space-between" alignItems="center">
          <Grid item xs={9}>
            <Typography variant="h5" data-testid="page-heading">
              Payment Notes
            </Typography>
            <Typography gutterBottom color="textSecondary">
              View payment notes
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Datepicker
              change={setDate}
              startDate={startOfYear(new Date())}
              endDate={endOfMonth(new Date())}
            />
          </Grid>
        </Grid>
        <Table
          pageCount={pageCount}
          currentPage={currentPage}
          handlePageChange={() => console.log('page change')}
          data={getSearchedData()}
          isLoading={isLoading}
          handleSearch={setSearchVal}
          handleSort={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          handleFilterClick={handleFilterClick}
          handlePDFDownloadClick={handlePDFDownloadClick}
          handleCSVDownloadClick={openAsCSV}
        />
      </Container>
    </>
  )
}
